import React, { useState, useEffect, useRef } from 'react'
import { debugMessage } from '../../common'
import { authenticator } from '../../authenticator'
import { Message, Image, Button, Modal, Input, Loader } from 'semantic-ui-react'
import { ajax } from '../../ajax'
import { getEnglishText } from '../../dictionary'

import './Weather.css'

let refreshHandle = null
let lastRequestTime = 0

function Weather({ unit }) {
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [data, setData] = useState({})

  const [postcodeEditorOpen, setPostcodeEditorOpen] = useState(false)

  const refreshInterval = 300000 // five minutes

  useEffect(() => {
    debugMessage('Mounted', 'Weather')
    return () => {
      clearTimeout(refreshHandle)
    }
  }, [])

  useEffect(() => {
    debugMessage('Updated', 'Weather')
    clearTimeout(refreshHandle)
    fetchData()
  }, [unit.unitId])

  function fetchData() {
    const idToken = authenticator.getToken()

    lastRequestTime = new Date().getTime()
    let thisRequestTime = new Date().getTime()

    setError(null)

    ajax
      .fetchWeather(unit.unitId, idToken)
      .then((weatherData) => {
        if (lastRequestTime !== thisRequestTime) {
          debugMessage('Data from old request, discarding', 'Weather')
          return
        }

        clearTimeout(refreshHandle)
        setIsLoaded(true)
        setData(weatherData.data)

        refreshHandle = setTimeout(() => fetchData(), refreshInterval) // set next refresh
      })
      .catch((error) => {
        setError(error)
      })
  }

  function getPostcodeData() {
    try {
      let { configPostcodeData } = unit
      if (!configPostcodeData) throw null
      configPostcodeData = JSON.parse(configPostcodeData)
      return configPostcodeData
    } catch (err) {
      try {
        // revert to using CustomerAccount postcode
        let { postcodeData } = unit
        postcodeData = JSON.parse(postcodeData)
        return postcodeData
      } catch (err) {
        return null
      }
    }
  }

  function getPostcode() {
    const postcodeData = getPostcodeData()
    try {
      return postcodeData['postcode']
    } catch (err) {
      return null
    }
  }

  function getLocationName() {
    const postcodeData = getPostcodeData()
    try {
      return postcodeData.parish ? postcodeData.parish.replace(', unparished area', '') : postcodeData.admin_district
    } catch (err) {
      return null
    }
  }

  const { weatherPhrase, weatherIconIndex, isDaylight, precipitationProbability, temperatureValue, temperatureUnit, weatherImageUrl } = data || {}
  const { configPostcode } = unit || {}

  return (
    <div id="weather">
      {isLoaded ? (
        <>
          {error && (
            <Message negative key="2">
              <p>Error: {error.message}</p>
            </Message>
          )}

          {!getPostcode() ? (
            <>
              <Button
                size="small"
                className="update-postcode-button"
                primary
                basic
                content={getEnglishText('weather : enter-your-postcode')}
                icon="plus"
                labelPosition="left"
                onClick={() => setPostcodeEditorOpen(true)}
              />
            </>
          ) : (
            <>
              {weatherPhrase ? (
                <>
                  <div className="hide-more">
                    <div className="postcode">
                      <a onClick={() => setPostcodeEditorOpen(true)}>{getPostcode()}</a>
                    </div>
                    <span className="location-name">
                      {getLocationName() ? `${getLocationName()} -` : ''} {weatherPhrase}
                    </span>
                  </div>
                  <span className="temperature">
                    {temperatureValue}&nbsp;
                    <sup>&deg;{temperatureUnit}</sup>
                  </span>
                  <Image src={weatherImageUrl} size="tiny" />
                </>
              ) : (
                <>
                  <em style={{ opacity: 0.7 }}>
                    {getEnglishText('weather : finding-weather-for')} <strong>{configPostcode}</strong> ...
                    <br />
                    {getEnglishText('weather : may-take-a-few-minutes')}
                  </em>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <Loader active inline />
        </>
      )}
      {postcodeEditorOpen && (
        <PostcodeEditor
          unit={unit}
          handleCancel={() => {
            setPostcodeEditorOpen(false)
          }}
          handleSave={() => {
            setPostcodeEditorOpen(false)

            // user will have to select their unit again
            localStorage.removeItem('UNIT_DATA')

            window.alert(getEnglishText('weather : the-app-will-reload'))
            window.location.reload()
          }}
        />
      )}
    </div>
  )
}

function PostcodeEditor({ unit, handleCancel, handleSave }) {
  const idToken = authenticator.getToken()
  const ref = useRef(null)
  const [settings, setSettings] = useState(null)
  const [message, setMessage] = useState(null)
  const [busy, setBusy] = useState(true)

  useEffect(() => {
    fetchSettings()
  }, [])

  function fetchSettings() {
    ajax
      .fetchSettings(unit.hardwareId, idToken)
      .then((settingsData) => {
        setSettings(settingsData)
        setBusy(false)
      })
      .catch((error) => {
        setMessage({ error: true, content: error })
        setBusy(false)
      })
  }

  function putPostcode() {
    setMessage(null)
    setBusy(true)

    ajax
      .putSettings(unit.hardwareId, idToken, settings)
      .then((response) => {
        const { result, errorMessage } = response
        if (result === 'success') {
          setBusy(false)
          handleSave()
        } else {
          setBusy(false)
          setMessage({ error: true, content: errorMessage })
        }
      })
      .catch((error) => {
        setMessage({ error: true, content: getEnglishText('weather : unknown-error') })
      })
  }

  function savePostcode() {
    if (!formIsValid()) return setMessage({ error: true, content: getEnglishText('weather : invalid-postcode') })
    putPostcode()
  }

  function formIsValid() {
    if (!settings) return false

    // UK postcode regex from UK Government:
    const regex =
      /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/

    return regex.test(settings.contract.postcode)
  }

  return (
    <Modal open size="mini" dimmer="blurring">
      <Modal.Header>{getEnglishText('weather : edit-your-postcode')}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <>
            <Input
              value={
                settings?.contract?.postcode
                  ? settings.contract.postcode.toUpperCase()
                  : settings?.customerAccount?.postcode
                  ? settings.customerAccount.postcode.toUpperCase()
                  : ''
              }
              ref={ref}
              placeholder={getEnglishText('weather : postcode')}
              fluid
              onChange={(e, { value }) =>
                setSettings({ contract: { ...settings.contract, postcode: value }, customerAccount: { ...settings.customerAccount } })
              }
              onKeyPress={(e) => e.key === 'Enter' && savePostcode()}
              disabled={busy}
            />
          </>
          {message && <Message {...message} />}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={!formIsValid() || busy} loading={busy} content={getEnglishText('common : save')} onClick={savePostcode} primary />
        <Button secondary onClick={handleCancel} disabled={busy} loading={busy}>
          {getEnglishText('common : cancel')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export { Weather }
