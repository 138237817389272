import React, { useEffect, useState, useRef } from 'react'
import { Modal, Button, Header, Checkbox, AccordionTitle, AccordionContent, Accordion, Icon, Divider, Segment, Message, Image } from 'semantic-ui-react'
import { Link, useNavigate } from 'react-router-dom'
import { ajax } from '../../ajax'
import { readLocalStorageUnitData } from '../../common'
import { toast } from 'react-toastify'

import './GatewaySettings.css'

import evChargerIcon from '../../assets/icons/ev-charger-padded.png'
import ashpIcon from '../../assets/icons/ashp-padded.png'
import dishwasherIcon from '../../assets/icons/dishwasher-padded.png'
import washingMachineIcon from '../../assets/icons/washing-machine-padded.png'
import tumbleDryerIcon from '../../assets/icons/tumble-dryer-padded.png'

///////////
// TOAST //
///////////

let toastTimeoutHandle = null
const toastLoading = () => {
  clearTimeout(toastTimeoutHandle)
  toastTimeoutHandle = setTimeout(() => {
    toast('Loading ...', { containerId: 'toastPreferences', toastId: 'myToast', type: 'info', autoClose: false })
  }, 1000)
}
const toastLoaded = () => {
  clearTimeout(toastTimeoutHandle)
  toast.dismiss({ id: 'myToast' })
}
const toastSaving = () => {
  clearTimeout(toastTimeoutHandle)
  toast('Saving ...', { containerId: 'toastPreferences', toastId: 'myToast', type: 'info', autoClose: false })
  toast.update('myToast', { containerId: 'toastPreferences', render: 'Saving ...', type: 'info', autoClose: false })
}
const toastSaved = () => {
  clearTimeout(toastTimeoutHandle)
  toast.update('myToast', { containerId: 'toastPreferences', render: 'Preferences saved.', type: 'success', autoClose: 3000 })
}

///////////
///////////
///////////

function GatewaySettings() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [toggleA, setToggleA] = useState(false)
  const [toggleB, setToggleB] = useState(false)

  const [accountContactDetails, setAccountContactDetails] = useState({ mobilePhoneNumber: '' })
  const [gatewayPreferences, setGatewayPreferences] = useState({
    severeWeatherAlertsEnabled: false,
    autoForceChargeEnabled: false,
    sendSmsNotifications: false
  })

  const { configId, accountId: customerAccountId } = readLocalStorageUnitData()[0]

  useEffect(() => {
    fetchGatewaySettings()
    return () => {}
  }, [])

  async function fetchCustomerAccountSettings() {
    try {
      const preferences = await ajax.getPreferences({ customerAccountId })
      setAccountContactDetails(preferences.accountContactDetails)
      setLoading(false)
      toastLoaded()
    } catch (error) {
      console.error(error)
    }
  }

  async function fetchGatewaySettings() {
    setLoading(true)
    toastLoading()
    await fetchCustomerAccountSettings()
    try {
      const preferences = await ajax.getPreferences({ configId })
      setGatewayPreferences(preferences.gateway)
      setLoading(false)
      toastLoaded()
    } catch (error) {
      console.error(error)
    }
  }

  async function postGatewaySettings(gatewayPreferences) {
    try {
      setSaving(true)
      toastSaving()
      await ajax.postPreferences({ configId }, { gateway: gatewayPreferences })
      setSaving(false)
      toastSaved()
    } catch (error) {
      console.error(error)
    }
  }

  const CustomCheckbox = ({ id, label, disabled = false }) => (
    <Checkbox
      toggle
      disabled={disabled}
      label={label}
      checked={gatewayPreferences?.[id]}
      id={id}
      onChange={async (e, { id, checked }) => {
        if (id === 'severeWeatherAlertsEnabled' && checked && gatewayPreferences?.severeWeatherAlertsEnabled === undefined) {
          // first time user has enabled, so also enable Auto-Force Charge
          setGatewayPreferences({ ...gatewayPreferences, [id]: checked, autoForceChargeEnabled: true })
          await postGatewaySettings({ ...gatewayPreferences, [id]: checked, autoForceChargeEnabled: true })
        } else {
          setGatewayPreferences({ ...gatewayPreferences, [id]: checked })
          await postGatewaySettings({ ...gatewayPreferences, [id]: checked })
        }
      }}
    />
  )

  return (
    <>
      <Modal.Header>
        <Icon name="cogs" />
        &nbsp;&nbsp;&nbsp;Gateway Settings
      </Modal.Header>
      <Modal.Content className="GatewaySettings" scrolling>
        <Segment basic vertical loading={loading}>
          <Header>Automated Force Charge Service</Header>

          <p>
            Stormy weather can cause power cuts. We scan local Met Office data for localised severe weather alerts with a power outage warning. Powervault can
            override your manual or SMARTSTOR<sup>&trade;</sup> schedule to Force Charge your battery to prepare for a power cut.
          </p>

          {gatewayPreferences?.severeWeatherAlertsEnabled && !accountContactDetails?.contactBySms ? (
            <>
              <Message warning content={<strong>Please enable SMS notifications in your profile settings.</strong>} />
            </>
          ) : (
            <></>
          )}

          <div style={{ paddingTop: '1em' }}>
            <CustomCheckbox id="severeWeatherAlertsEnabled" label="Opt-in to receive severe weather warnings via SMS" />
          </div>

          <br />

          <p style={{ paddingLeft: '1em', paddingTop: '1em' }}>
            Be ready for a power cut with a full battery without having to do anything yourself.&nbsp;&nbsp;Do you give permission for Powervault to Force
            Charge your battery?
          </p>

          <div style={{ padding: '1em' }}>
            <CustomCheckbox
              id="autoForceChargeEnabled"
              label="Automatically Force Charge my Powervault"
              disabled={gatewayPreferences?.severeWeatherAlertsEnabled === false || gatewayPreferences?.severeWeatherAlertsEnabled === undefined}
            />
          </div>

          <Accordion className="indented">
            <AccordionTitle active={toggleA} onClick={() => setToggleA(!toggleA)}>
              <Icon name="dropdown" />
              Learn more ...
            </AccordionTitle>
            <AccordionContent active={toggleA}>
              <p>Force Charge activates when the alert is received via API from the Met Office and returns to Force Normal afterwards.</p>
              <p>Force Charge is set to 6 hours or when the battery reaches 100%.</p>
              <p>Please give us your mobile number and consent to update you by SMS. This is found in My profile > Account Details</p>
              <p>We will send you an SMS to confirm each charging event, you will be able to stop any charging event by clicking a link in the SMS. </p>
            </AccordionContent>
          </Accordion>

          {/* <br />
          <p style={{ paddingLeft: '1em' }}>Please tell us below if you would like to receive SMS notifications.</p>
          <div style={{ padding: '1em' }}>
            <CustomCheckbox
              id="sendSmsNotifications"
              label="Send me SMS notifications"
              disabled={gatewayPreferences?.severeWeatherAlertsEnabled === false || gatewayPreferences?.severeWeatherAlertsEnabled === undefined}
            />
          </div>

          <Accordion className="indented">
            <AccordionTitle active={toggleB} onClick={() => setToggleB(!toggleB)}>
              <Icon name="dropdown" />
              Learn more ...
            </AccordionTitle>
            <AccordionContent active={toggleB}>
              <p>Using Met Office data provides accurate and reliable weather forecasts with power cut predictions.</p>
              <p>If you have opted in to severe weather event Automated Force Charging we will send you an SMS when charging starts.</p>
              <p>If you have opted out, we will send you an SMS to advise you to manually Force Charge your battery.</p>
            </AccordionContent>
          </Accordion> */}

          <Divider hidden />
          <Divider />
        </Segment>

        <Segment basic vertical loading={loading}>
          <Header>Managing your energy during a power cut</Header>

          <p>
            Once you have a fully-charged battery, you will need to manage your power reserve by unplugging or turning off energy-intensive devices and
            appliances.
          </p>
          <div className="appliances">
            <Header as="h4" color="green">
              Standard Appliances
              <Header.Subheader>Use normally</Header.Subheader>
            </Header>
            <div>
              <Icon size="big" circular name="coffee" label="test" />
              <small>Kettle</small>
            </div>
            <div>
              <Icon size="big" circular name="mobile alternate" />
              <small>Phone</small>
            </div>
            <div>
              <Icon size="big" circular name="lightbulb outline" />
              <small>Lights</small>
            </div>
            <div>
              <Icon size="big" circular name="television" />
              <small>Television</small>
            </div>
            <div>
              <Icon size="big" circular name="laptop" />
              <small>Laptop</small>
            </div>
            <div>
              <Icon size="big" circular name="wifi" />
              <small>Internet Router</small>
            </div>
            <div>
              <Icon size="big" circular name="video camera" />
              <small>CCTV</small>
            </div>

            <Header as="h4" color="red">
              Energy-Intensive Appliances
              <Header.Subheader>Consider not using these</Header.Subheader>
            </Header>
            <div>
              <Image src={evChargerIcon} circular bordered className="custom-icon" />
              <small>EV Charging</small>
            </div>
            <div>
              <Image src={ashpIcon} circular bordered className="custom-icon" />
              <small>Air Source Heat Pump</small>
            </div>

            <div>
              <Image src={dishwasherIcon} circular bordered className="custom-icon" />
              <small>Dishwasher</small>
            </div>
            <div>
              <Image src={washingMachineIcon} circular bordered className="custom-icon" />
              <small>Washing Machine</small>
            </div>
            <div>
              <Image src={tumbleDryerIcon} circular bordered className="custom-icon" />
              <small>Tumble Dryer</small>
            </div>

            <div>
              <Icon size="big" circular name="shower" />
              <small>Electric Shower</small>
            </div>
            <div>
              <Icon size="big" circular name="thermometer" />
              <small>Electric Heating</small>
            </div>
          </div>

          {/* <p>
            Your <strong>Live View</strong> shows an estimation of how long the battery energy could last, calculated by analysing the current home energy
            consumption rate, so you can make an informed decision about what you don't need whilst off-grid.
          </p>

          <p>
            You've charged your battery and unplugged any energy-intensive devices but you may want to actively manage the level of energy stored in your
            Powervault battery.
          </p>
          <p>
            The countdown timer on the <strong>Live View</strong> shows an estimation of how long the battery energy could last so you can turn off
            non-essential devices.
          </p>
          <p>The good news is the Powervault battery will continue to charge off-grid if there is good enough solar generation.</p>
          <p>
            In the event of a power cut, stay in touch with your utility company for information on how long the power cut will last, especially for planned
            maintenance scenarios.
          </p> */}
          <Divider hidden />
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={loading || saving} secondary onClick={() => navigate(-1)}>
          Done
        </Button>
      </Modal.Actions>
    </>
  )
}

export { GatewaySettings }
