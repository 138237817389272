import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { ajax } from '../../ajax'
import { authenticator } from '../../authenticator'
import { debugMessage, deDupeUnitList } from '../../common'
import { getEnglishText } from '../../dictionary'
import { findClusters } from '../../lib/groupUnitData'

import { Grid, Divider, Message, Card, SidebarPushable, SidebarPusher } from 'semantic-ui-react'

import { InitialLoader } from './InitialLoader'

import { UnitSelector } from './UnitSelector'
import { NavBar } from '../NavBar/NavBar'
import { Footer } from '../Footer/Footer'
import { Settings } from '../Settings/Settings'
import { Totals } from '../Totals/Totals'
import { Charts } from '../Charts/Charts'
import { LiveView } from '../LiveView/LiveView'
import { Announcements } from '../Announcements/Announcements'
import { SendToMobile } from '../SendToMobile/SendToMobile'
import { Warranty } from '../Warranty/Warranty'
import { MainMenu } from '../MainMenu/MainMenu'
import { UnknownEmail } from './UnknownEmail'

import './Home.css'

const DEBUG_HIDE_LIVE_VIEW = false
const DEBUG_HIDE_CHARTS = false
const DEBUG_HIDE_TOTALS = false
const DEBUG_HIDE_SETTINGS = false

const _debugMessage = (message) => debugMessage(message, 'Home')

function Home() {
  const { pathname } = useLocation()

  const [initialLoad, setInitialLoad] = useState(true)
  const [initialLoadStage, setInitialLoadStage] = useState(getEnglishText('loader : stage1'))

  const [showUnitSelector, setShowUnitSelector] = useState(false)

  const [mainMenuOpen, setMainMenuOpen] = useState(false)

  const [dateLocal, setDateLocal] = useState(moment().startOf('day'))
  const [user, setUser] = useState({})
  const [units, setUnits] = useState([])
  const [checkedUnits, setCheckedUnits] = useState([])

  const [dnoOverride, setDnoOverride] = useState(false)
  const [epsGridLoss, setEpsGridLoss] = useState(false)
  const [epsOverConsumption, setEpsOverConsumption] = useState(false)
  const [epsLowSoC, setEpsLowSoC] = useState(false)

  const [showWarrantyModal, setShowWarrantyModal] = useState(false)

  const [device, setDevice] = useState(handleWindowSizeChange(null, true))

  const [scheduleNudge, setScheduleNudge] = useState()

  const [clusters, setClusters] = useState([])

  const [showUnknownEmailModal, setShowUnknownEmailModal] = useState(false)

  const idToken = authenticator.getToken()

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    _debugMessage('Mounted')
    fetchData()

    return () => {
      _debugMessage('Unmounted')
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    // show / hide main menu
    setMainMenuOpen(pathname.includes('/home/account'))
  }, [pathname])

  useEffect(() => {
    if (units && units[0]) {
      // detect missing warranty activations and show modal if present
      const missingWarrantyActivations = units.filter((unit) => {
        return unit.dateWarrantyActivated === null && unit.relationship === 'owner'
      })
      if (missingWarrantyActivations.length) setShowWarrantyModal(true)
    }
  }, [units])

  useEffect(() => {
    // update clusters when checked units change
    if (checkedUnits && checkedUnits[0]) {
      if (checkedUnits) setClusters(findClusters(checkedUnits.map((u) => ({ unit: u }))))
      else setClusters([])
    }
  }, [checkedUnits])

  function handleWindowSizeChange(e, skipSet = false) {
    const { innerWidth } = window
    let _device = ''
    if (innerWidth < 768) {
      _device = 'mobile'
    } else if (innerWidth < 1700) {
      _device = 'desktop'
    } else {
      _device = 'desktop-large'
    }
    if (!skipSet) setDevice(_device)
    return _device
  }

  function fetchData() {
    _debugMessage('Fetching user data ...')
    setInitialLoadStage(getEnglishText('loader : stage2'))
    ajax
      .fetchUserAuth(idToken)
      .then((authData) => {
        const { data } = authData
        const units = data && data.units ? deDupeUnitList(data.units) : []

        if (units && units.length) {
          //////////////
          // Set USER //
          //////////////
          setUser(data)

          // Read unit data from cookie
          let storedUnitData = localStorage.getItem('UNIT_DATA')
          try {
            if (!storedUnitData.length) throw '' // jump to catch

            // attempt to parse cookie
            storedUnitData = JSON.parse(storedUnitData)['units']

            if (!storedUnitData || !storedUnitData.length) throw '' // jump to catch

            // make into array if not already
            if (!Array.isArray(storedUnitData)) storedUnitData = [storedUnitData]

            //////////////////////////
            // Set UNIT from cookie //
            //////////////////////////
            _debugMessage('User unit retrieved from cookie')
            setUnits(storedUnitData)
            setCheckedUnits(storedUnitData)
          } catch (e) {
            /////////////////////
            // No stored cooke //
            /////////////////////

            if (units.length === 1) {
              // User has only one unit
              _debugMessage('User has one single unit')
              localStorage.setItem('UNIT_DATA', JSON.stringify({ units }))

              ////////////////////////////////////////
              // Set UNIT to single unit from fetch //
              ////////////////////////////////////////
              setUnits([units[0]])
              setCheckedUnits([units[0]])
            } else {
              // User has multiple units
              _debugMessage('User has multiple units')
              localStorage.removeItem('UNIT_DATA')

              //////////////////////////////////////////////
              // Set UNIT to empty and show Unit Selector //
              //////////////////////////////////////////////
              setUnits([])
              setCheckedUnits([])

              setShowUnitSelector(true)
              // navigate('/home/select-unit')
            }
          }
        } else {
          // no units associated with user!
          _debugMessage('No units associated with user!')

          localStorage.removeItem('UNIT_DATA')

          //////////////////////////////////////////////
          // Set UNIT to empty and enable noUnits tag //
          //////////////////////////////////////////////
          setUnits([])
          setCheckedUnits([])

          if (user) {
            setUser({ ...data, noUnits: true })
            setShowUnitSelector(true)
            // navigate('/home/select-unit')
          } else {
            setShowUnknownEmailModal(true)
          }
        }
        setInitialLoad(false)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  function sortUnits(_units) {
    // Scarlett's suggestion to sort selected units:
    return _units.sort((a, b) => {
      if (a.clusterName && b.clusterName && a.clusterSelected === true && b.clusterSelected === true) {
        // sort by clusterName if it is available
        if (a.clusterName < b.clusterName) return -1
        if (a.clusterName > b.clusterName) return 1
        return 0
      } else if (a.customName && b.customName) {
        // sort by customName if it is available
        if (a.customName < b.customName) return -1
        if (a.customName > b.customName) return 1
        return 0
      } else {
        // otherwise sort by hardwareId
        if (a.hardwareId < b.hardwareId) return -1
        if (a.hardwareId > b.hardwareId) return 1
        return 0
      }
    })
  }

  function selectUnits(selectedUnitData) {
    const { units: selectedUnits } = selectedUnitData

    const sortedSelectedUnits = sortUnits(selectedUnits)
    _debugMessage('selectUnits()')
    localStorage.setItem('UNIT_DATA', JSON.stringify({ units: sortedSelectedUnits }))
    setUnits(sortedSelectedUnits)
    setCheckedUnits(sortedSelectedUnits)
    setShowUnitSelector(false)
  }

  function currentUnitsAreAssignedToUser() {
    if (!units || !units[0] || !units[0].unitId) return false

    const userUnits = user.units
    const selectedUnits = units

    const matches = userUnits.filter((userUnit) => {
      const matches = selectedUnits.filter((selectedUnit) => selectedUnit.unitId === userUnit.unitId)
      return matches.length > 0
    })
    return matches.length > 0
  }

  function SelectedUnitsBar() {
    const displayAsClusters = units[0].clusterSelected === true

    const unitsGroupedByCluster = {}
    units.forEach((u) => {
      if (!unitsGroupedByCluster[u.clusterHash]) unitsGroupedByCluster[u.clusterHash] = []
      unitsGroupedByCluster[u.clusterHash].push(u)
    })
    const clusters = Object.keys(unitsGroupedByCluster).map((k) => unitsGroupedByCluster[k])

    return (
      <div className="selected-units-bar">
        <Card.Group centered>
          {displayAsClusters ? (
            <>
              {clusters.map((clusterUnits, index) => {
                const { clusterName, clusterHash } = clusterUnits[0]

                const clusterCustomNames = clusterUnits.map((u) => u.customName || u.hardwareId).sort()

                return (
                  <Card
                    className="cluster pv-primary-background-color"
                    key={index}
                    onClick={(e) => {
                      const isChecked = checkedUnits.filter((checkedUnit) => checkedUnit.clusterHash === clusterHash).length > 0
                      if (isChecked) {
                        if (checkedUnits.length === clusterUnits.length) return
                        setCheckedUnits(checkedUnits.filter((checkedUnit) => checkedUnit.clusterHash !== clusterHash))
                      } else {
                        setCheckedUnits([...checkedUnits, ...clusterUnits])
                      }
                    }}
                  >
                    <Card.Content>
                      <Card.Header>
                        Cluster: {clusterName}
                        <br />
                        <small style={{ opacity: 0.5 }}>
                          {clusterCustomNames.map((name, index) => (
                            <React.Fragment key={index}>
                              {name}
                              <br />
                            </React.Fragment>
                          ))}
                        </small>
                      </Card.Header>

                      <input
                        className="checkbox"
                        type="checkbox"
                        defaultChecked={checkedUnits.filter((checkedUnit) => checkedUnit.clusterHash === clusterHash).length > 0}
                        onClick={(e) => {
                          // prevent checkbox being unchecked if this is the only one selected
                          if (checkedUnits.length === clusterUnits.length) e.target.checked = true
                        }}
                        style={{ cursor: 'pointer', opacity: 0.75, width: '1.5em', height: '1.5em', position: 'absolute', top: 12, right: '1em' }}
                      />
                    </Card.Content>
                  </Card>
                )
              })}
            </>
          ) : (
            <>
              {units.map((unit, index) => {
                const { unitId, customName } = unit

                return (
                  <Card
                    style={{ marginTop: 0 }}
                    key={index}
                    onClick={
                      units.length > 1
                        ? (e) => {
                            const isChecked = checkedUnits.filter((checkedUnit) => checkedUnit.unitId === unitId).length > 0
                            if (isChecked) {
                              if (checkedUnits.length === 1) return
                              setCheckedUnits(checkedUnits.filter((checkedUnit) => checkedUnit.unitId !== unitId))
                            } else {
                              setCheckedUnits([...checkedUnits, unit])
                            }
                          }
                        : null
                    }
                  >
                    <Card.Content>
                      {customName ? (
                        <Card.Header>
                          {customName} <small style={{ opacity: 0.5 }}>({unitId})</small>
                        </Card.Header>
                      ) : (
                        <Card.Header>{unitId}</Card.Header>
                      )}

                      {/* <InternalLink to="/home/account" state={{ renameHardwareId: unitId }}>
                        Rename
                      </InternalLink> */}

                      {units.length > 1 && (
                        <input
                          className="checkbox"
                          type="checkbox"
                          defaultChecked={checkedUnits.filter((checkedUnit) => checkedUnit.unitId === unitId).length > 0}
                          onClick={(e) => {
                            // prevent checkbox being unchecked if this is the only one selected
                            if (checkedUnits.length === 1) e.target.checked = true
                          }}
                          style={{ cursor: 'pointer', opacity: 0.75, width: '1.5em', height: '1.5em', position: 'absolute', top: 12, right: '1em' }}
                        />
                      )}
                    </Card.Content>
                  </Card>
                )
              })}
            </>
          )}
        </Card.Group>
      </div>
    )
  }

  function isSettingsVisible() {
    if (checkedUnits.length === 1) return true
    if (clusters.length === 1 && checkedUnits[0].clusterHash) return true
    return false
  }

  return (
    <div id="home" device={device}>
      <SidebarPushable>
        <MainMenu user={user} units={units} visible={mainMenuOpen} />

        <SidebarPusher dimmed={mainMenuOpen}>
          <>
            {/* NavBar */}

            <NavBar
              user={user}
              handleSelectUnitClick={() => {
                setShowUnitSelector(true)
              }}
              noUnits={user.noUnits}
            />

            {/* Dashboard */}

            {(currentUnitsAreAssignedToUser() || (user.user && user.user.isInstaller && user.noUnits)) && (
              // <Container fluid>
              <div className="dashboard">
                {units.length > 0 && <SelectedUnitsBar />}

                <Grid stackable padded stretched style={{ width: '100%' }} verticalAlign="top" className="stretch-height">
                  <Grid.Row>
                    <Grid.Column width={device === 'desktop-large' ? 12 : 16}>
                      <Grid stackable stretched verticalAlign="top" className="stretch-height">
                        {/* //////////////////////////////////// */}
                        {/* //////////////////////////////////// */}
                        {/* //////////////////////////////////// */}

                        <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }} className="collapse-if-empty">
                          <Grid.Column width={16} className="no-padding-when-stacked">
                            {/* Announcements */}

                            <Announcements />

                            {/* Messages */}

                            {dnoOverride && (
                              <Message
                                className="pv-dashboard-transparent-message"
                                negative
                                icon="exclamation triangle"
                                header={getEnglishText('alerts : dno-override-received-title')}
                                content={getEnglishText('alerts : dno-override-received-body')}
                              />
                            )}
                            {epsGridLoss && (
                              <Message
                                className="pv-dashboard-transparent-message"
                                warning
                                icon="exclamation triangle"
                                header={getEnglishText('alerts : eps-grid-loss-title')}
                                content={getEnglishText('alerts : eps-grid-loss-body')}
                              />
                            )}
                            {epsOverConsumption && (
                              <Message
                                className="pv-dashboard-transparent-message"
                                negative
                                icon="exclamation triangle"
                                header={getEnglishText('alerts : eps-over-consumption-title')}
                                content={getEnglishText('alerts : eps-over-consumption-body')}
                              />
                            )}
                            {epsLowSoC && (
                              <Message
                                className="pv-dashboard-transparent-message"
                                negative
                                icon="exclamation triangle"
                                header={getEnglishText('alerts : eps-low-soc-title')}
                                content={getEnglishText('alerts : eps-low-soc-body')}
                              />
                            )}
                          </Grid.Column>
                        </Grid.Row>

                        {/* //////////////////////////////////// */}
                        {/* //////////////////////////////////// */}
                        {/* //////////////////////////////////// */}

                        <Grid.Row>
                          <Grid.Column width={6}>
                            {!DEBUG_HIDE_LIVE_VIEW && (
                              <>
                                <LiveView
                                  units={checkedUnits}
                                  setDnoOverride={setDnoOverride}
                                  setEpsGridLoss={setEpsGridLoss}
                                  setEpsOverConsumption={setEpsOverConsumption}
                                  setEpsLowSoC={setEpsLowSoC}
                                  isStaff={user.user.isStaff}
                                />
                              </>
                            )}
                          </Grid.Column>
                          <Grid.Column width={10}>
                            {!DEBUG_HIDE_CHARTS && (
                              <>
                                <Charts
                                  units={checkedUnits}
                                  handleDateChange={(newDate) => {
                                    setDateLocal(newDate)
                                  }}
                                  isInstaller={user.user.isInstaller}
                                  isStaff={user.user.isStaff}
                                  scheduleNudge={scheduleNudge}
                                />
                              </>
                            )}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                    {device === 'desktop-large' && (
                      <Grid.Column width={4}>
                        {!DEBUG_HIDE_TOTALS && (
                          <>
                            <Totals units={checkedUnits} dateLocal={dateLocal} />
                          </>
                        )}
                      </Grid.Column>
                    )}
                  </Grid.Row>
                  <Grid.Row>
                    {device !== 'desktop-large' && (
                      <Grid.Column width={6}>
                        {!DEBUG_HIDE_TOTALS && (
                          <>
                            <Totals units={checkedUnits} dateLocal={dateLocal} />
                          </>
                        )}
                      </Grid.Column>
                    )}
                    <Grid.Column width={device !== 'desktop-large' ? 10 : 16}>
                      {!DEBUG_HIDE_SETTINGS && (
                        <>
                          {isSettingsVisible() && ( // only display if one unit selected, or one cluster selected
                            <Settings
                              device={device}
                              viewerDevice={device}
                              user={user}
                              units={checkedUnits}
                              clusters={clusters}
                              unitId={checkedUnits[0].unitId}
                              unitDetails={checkedUnits[0]}
                              onSaveSchedule={(schedule) => {
                                setScheduleNudge(schedule)
                              }}
                              isStaff={user.user.isStaff}
                            />
                          )}
                        </>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            )}

            {/* Unit Selector */}

            {user && user.user && (
              <UnitSelector
                user={user}
                showModal={showUnitSelector}
                isStaff={user.user.isStaff}
                device={device}
                units={user.units}
                noUnits={user.noUnits}
                setShowUnitSelector={setShowUnitSelector}
                callbackSelectedUnits={(selectedUnitData) => {
                  selectUnits(selectedUnitData)
                }}
              />
            )}

            {/* Footer */}

            <Divider hidden />
            {(currentUnitsAreAssignedToUser() || (user.user && user.user.isInstaller && user.noUnits)) && (
              <>
                <Footer />
              </>
            )}

            {/* SendToMobile */}

            <SendToMobile />

            {showWarrantyModal && (
              <Warranty
                onClose={() => {
                  setShowWarrantyModal(false)
                  // clear local data and reload
                  window.localStorage.removeItem('UNIT_DATA')
                  window.location.reload()
                }}
                units={units.filter((unit) => {
                  return !unit.dateWarrantyActivated && unit.relationship === 'owner'
                })}
              />
            )}

            {showUnknownEmailModal && <UnknownEmail />}
          </>
        </SidebarPusher>
      </SidebarPushable>

      {<InitialLoader loading={initialLoad} stage={initialLoadStage} />}
    </div>
  )
}

export { Home }
