import React, { useState, useEffect } from 'react'
import { ajax } from '../../ajax'
import { moment, parseModelCode } from '../../common'
import { Label, Icon, Popup } from 'semantic-ui-react'

import './GatewayStatus.css'

const UPDATE_INTERVAL_MS = 5000
const FRESH_DATA_THRESHOLD_SECONDS = 60 * 5 // 5 minutes
let timeoutHandle = null

function GatewayStatus({ units, onUpdate }) {
  const [gatewayStatus, setGatewayStatus] = useState([])

  useEffect(() => {
    return () => clearTimeout(timeoutHandle)
  }, [])

  useEffect(() => {
    fetchData()
  }, [units])

  useEffect(() => {
    if (onUpdate) onUpdate(gatewayStatus)
  }, [gatewayStatus])

  async function fetchData() {
    const response = await ajax.fetchLastMeasurement({
      hardwareId: units[0].hardwareId,
      measurementName: 'gatewayConnected'
    })
    const lastDataTime = response?.lastData?.gatewayConnected[0]
    const dataIsFresh = moment.utc(lastDataTime).isAfter(moment.utc().subtract(FRESH_DATA_THRESHOLD_SECONDS, 'seconds'))

    if (!dataIsFresh) return { hardwareId: units[0].hardwareId, status: 'not-connected' }

    const gatewayValue = response?.lastData?.gatewayConnected[1]

    setGatewayStatus({
      hardwareId: units[0].hardwareId,
      status: gatewayValue === 0 ? 'online-eps-mode' : gatewayValue === 1 ? 'online-grid-mode' : 'not-connected'
    })

    clearTimeout(timeoutHandle)
    timeoutHandle = setTimeout(() => fetchData(), UPDATE_INTERVAL_MS)
  }

  return (
    <div className="GatewayStatus">
      {gatewayStatus.status === 'online-grid-mode' && (
        <Label color="green" basic size="small">
          Gateway online &nbsp;&nbsp;&nbsp;
          <Icon name="check" fitted />
        </Label>
      )}
      {gatewayStatus.status === 'online-eps-mode' && (
        <Label color="orange" basic size="small">
          Gateway in EPS mode &nbsp;&nbsp;&nbsp;
          <Icon name="check" fitted />
        </Label>
      )}
    </div>
  )
}

export { GatewayStatus }
