import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { ajax } from '../../ajax'
import { authenticator } from '../../authenticator'
import { Weather } from '../Weather/Weather'
import { parseModelCode, debugMessage, friendlyFormat, readLocalStorageUnitData } from '../../common'
import { Message, Loader, Header, Icon, Divider, Dimmer, Popup, Segment, Button } from 'semantic-ui-react'
import { sumChartData } from '../../lib/groupUnitData'

import { ForceChargeDischarge } from './ForceChargeDischarge'
import { TimeRemaining } from './TimeRemaining'
import { VOCSensor } from './VOCSensor'
import { GatewayStatus } from './GatewayStatus'

import { getEnglishText } from '../../dictionary'

import gridIcon from '../../assets/icons/grid.png'
import solarIcon from '../../assets/icons/solar.png'

import evChargerIcon from '../../assets/icons/ev-charger.png'
import ashpIcon from '../../assets/icons/ashp.png'

import './LiveView.css'

let refreshHandle = null
let lastRequestTime = 0
let retries = 0

const MIN_VALID_POWER_MW = 50000 // 50W
const REFRESH_INTERVAL_MS = 5000 // 5 seconds
const DEBUG_NO_REFRESH = false

///////////////
// COMPONENT //
///////////////

function LiveView({ units, setDnoOverride, setEpsGridLoss, setEpsOverConsumption, setEpsLowSoC }) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [data, setData] = useState([])
  const [lastDataTime, setLastDataTime] = useState(moment())

  const [error, setError] = useState(null)
  const [warning, setWarning] = useState(null)
  // const [retries, setRetries] = useState(0)

  const [gatewayStatus, setGatewayStatus] = useState(null)

  const [clusters, setClusters] = useState([])

  const [showMore, setShowMore] = useState(false)

  const [auxClampPreferences, setAuxClampPreferences] = useState({})

  const { configId } = readLocalStorageUnitData()[0]

  useEffect(() => {
    debugMessage('Mounted', 'Live View')

    return () => {
      clearTimeout(refreshHandle)
    }
  }, [])

  useEffect(() => {
    clearTimeout(refreshHandle)
    debugMessage('Updated', 'Live View')
    fetchClampPreferences()
    setIsLoaded(false)
    fetchData()
  }, [units])

  async function fetchClampPreferences() {
    try {
      const preferences = await ajax.getPreferences({ configId })
      setAuxClampPreferences(preferences.auxClamps)
    } catch (error) {
      console.error(error)
    }
  }

  // Function to get latest non-null values
  function getLatestNonNull(series) {
    const targetFields = [
      'instant_battery',
      'instant_demand',
      'instant_grid',
      'instant_solar',
      'instant_soc',
      'instant_aux2',
      'instant_aux3',
      'gridClampDisconnected',
      'dnoOverride',
      'epsGridLoss',
      'epsOverConsumption',
      'epsLowSoC'
    ]

    const result = {}
    const sorted = [...series].sort((a, b) => new Date(b.time) - new Date(a.time))

    let instantSocFound = false // Flag to track if any instant_soc is non-null

    for (const entry of sorted) {
      for (const key of targetFields) {
        const value = entry[key]
        if (key === 'instant_soc' && value !== null && value !== undefined) {
          instantSocFound = true // We found a valid instant_soc value
        }
        if (value !== null && value !== undefined && result[key] === undefined) {
          result[key] = value
        }
      }
    }

    // If no valid instant_soc found, always return false
    if (!instantSocFound) {
      return false
    }

    // If every other value is null or undefined, return false
    const hasAnyRealValue = Object.values(result).some((val) => val !== null && val !== undefined)
    return hasAnyRealValue ? result : false
  }

  function fetchData() {
    setWarning()
    if (!units || !units[0]) return setWarning(getEnglishText('live-view : no-unit-id'))

    const idToken = authenticator.getToken()

    let params = {
      units,
      timePreset: 'last-60-seconds',
      liveView: true
    }

    lastRequestTime = new Date().getTime()
    let thisRequestTime = new Date().getTime()

    if (retries === 1) {
      params.timePreset = 'last-2-minutes'
    } else if (retries === 2) {
      params.timePreset = 'last-3-minutes'
    } else if (retries === 3) {
      params.timePreset = 'last-4-minutes'
    } else if (retries > 3) {
      params.timePreset = 'last-5-minutes'
    }

    setError(null)

    ajax
      .fetchChartData(idToken, { ...params })
      .then(({ clusters }) => {
        if (lastRequestTime !== thisRequestTime) {
          setError(null)
          debugMessage('Data from old request, discarding', 'Live View')
          return
        }

        let chartData = null
        // sum chart data if more than one unit
        if (clusters.length > 1) {
          chartData = sumChartData(clusters)
          setClusters(clusters)
        } else {
          chartData = clusters[0]
          setClusters([])
        }

        // select non-null values from rows
        const latestValues = getLatestNonNull(chartData.series)

        // all nulls returned
        if (latestValues === false) {
          retries++
        } else {
          retries = 0
          setData(latestValues)
          setError(null)
          setIsLoaded(true)
        }
      })
      .catch((error) => {
        debugMessage(error)
        setError(error)
      })

    clearTimeout(refreshHandle) // clear existing refresh handle
    if (!DEBUG_NO_REFRESH) refreshHandle = setTimeout(() => fetchData(), REFRESH_INTERVAL_MS) // set next refresh
  }

  function TooLong(props) {
    return (
      <Dimmer active inverted>
        <Loader>{getEnglishText('live-view : too-long')}</Loader>
      </Dimmer>
    )
  }

  function WayTooLong(props) {
    return (
      <Dimmer active inverted>
        <Loader>{getEnglishText('live-view : way-too-long')}</Loader>
      </Dimmer>
    )
  }

  function getClampInfo(clampId) {
    switch (auxClampPreferences?.[clampId]) {
      case 'ev-charger':
        return {
          name: 'EV Charger',
          image: evChargerIcon
        }
      case 'ashp':
        return {
          name: 'Air Source Heat Pump',
          image: ashpIcon
        }
      default:
        return {
          name: 'Generic Device',
          icon: 'microchip'
        }
    }
  }

  function handleGatewayUpdate(status) {
    if (!status || !status?.status) setGatewayStatus(null)
    setGatewayStatus(status)
  }

  function LiveViewChart() {
    const series = data

    const gridClampDisconnected = series?.gridClampDisconnected || 0

    setDnoOverride(series.dnoOverride > 0)
    setEpsGridLoss(series.epsGridLoss > 0)
    setEpsOverConsumption(series.epsOverConsumption > 0)
    setEpsLowSoC(series.epsLowSoC > 0)

    let instant_battery = series?.instant_battery || 0
    let instant_demand = series?.instant_demand || 0
    let instant_grid = series?.instant_grid || 0
    let instant_solar = series?.instant_solar || 0
    let instant_soc = series?.instant_soc || 0
    let instant_aux2 = series?.instant_aux2 || 0
    let aux2Connected = auxClampPreferences?.aux2
    let instant_aux3 = series?.instant_aux3 || 0
    let aux3Connected = auxClampPreferences?.aux3

    const timeSinceDataMs = moment().diff(lastDataTime)

    let stateOfChargeIcon = null
    let stateOfChargeIconClass = null
    let stateOfChargeText = [<>{getEnglishText('live-view : state-of-charge')}:</>]
    let stateOfChargeTextClass = ''

    instant_soc = parseAccurateSoc(units, instant_soc)

    if (instant_soc < 20) {
      stateOfChargeIcon = 'battery empty'
      stateOfChargeIconClass = 'pv-primary-color'
      stateOfChargeTextClass = 'pv-primary-color'
      stateOfChargeText.push(<> 0% - 20%</>)
    } else if (instant_soc < 40) {
      stateOfChargeIcon = 'battery one'
      stateOfChargeIconClass = 'pv-primary-color'
      stateOfChargeTextClass = 'pv-primary-color'
      stateOfChargeText.push(<> 20% - 40%</>)
    } else if (instant_soc < 60) {
      stateOfChargeIcon = 'battery two'
      stateOfChargeIconClass = 'pv-primary-color'
      stateOfChargeTextClass = 'pv-primary-color'
      stateOfChargeText.push(<> 40% - 60%</>)
    } else if (instant_soc < 80) {
      stateOfChargeIcon = 'battery three'
      stateOfChargeIconClass = 'pv-primary-color'
      stateOfChargeTextClass = 'pv-primary-color'
      stateOfChargeText.push(<> 60% - 80%</>)
    } else {
      stateOfChargeIcon = 'battery four'
      stateOfChargeIconClass = 'pv-primary-color'
      stateOfChargeTextClass = 'pv-primary-color'
      stateOfChargeText.push(<> 80% - 100%</>)
    }

    if (showAccurateSoc(units)) {
      stateOfChargeText = [
        <>
          {getEnglishText('live-view : state-of-charge')}: {instant_soc}%
        </>
      ]
    }

    if (clusters.length > 1)
      stateOfChargeText.push(
        <>
          <br />
          {`(averaged accross ${clusters.length} clusters)`}
        </>
      )

    const magnitudeExp = 0.0000025

    const clampWidth = (width) => {
      const minWidth = 1.8
      const maxWidth = 8
      if (width === 0) {
        return 0
      } else if (width > maxWidth) {
        return maxWidth
      } else if (width < minWidth) {
        return minWidth
      } else {
        return width ? width : 0
      }
    }

    // filter input values to zero
    if (instant_grid < MIN_VALID_POWER_MW && instant_grid > -MIN_VALID_POWER_MW) instant_grid = 0 //          Zero imports below 100W, Zero exports below 100W  Allow + and -

    if (instant_solar < MIN_VALID_POWER_MW) instant_solar = 0 //                                  Zero inports below 100W                            Only allow +
    if (instant_battery < MIN_VALID_POWER_MW && instant_battery > -MIN_VALID_POWER_MW) instant_battery = 0 //  Zero imports and exports below 100W                Allow + and -
    if (instant_demand > -MIN_VALID_POWER_MW) instant_demand = 0 //                               Zero consumption below 100W                        Only allow -

    if (instant_aux2 < MIN_VALID_POWER_MW && instant_aux2 > -MIN_VALID_POWER_MW) instant_aux2 = 0
    if (instant_aux3 < MIN_VALID_POWER_MW && instant_aux3 > -MIN_VALID_POWER_MW) instant_aux3 = 0

    // make instant_grid = 0 if gridClampDisconnected = 1
    if (gridClampDisconnected) instant_grid = 0

    /////////////////////////////////////////////////////////////
    /////////////////////////// TEST ////////////////////////////
    /////////////////////////////////////////////////////////////
    /* Uncomment to use test values: */
    // instant_grid = 2000000
    // instant_solar = 2000000
    // instant_battery = 2000000
    // instant_demand = 2000000
    // instant_aux2 = 1000000
    // instant_aux3 = 1000000
    // aux2Connected = true
    // aux3Connected = true
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    let gridWidth = clampWidth(Math.abs(instant_grid * magnitudeExp))
    let solarWidth = clampWidth(Math.abs(instant_solar * magnitudeExp))
    let batteryWidth = clampWidth(Math.abs(instant_battery * magnitudeExp))
    let homeWidth = clampWidth(Math.abs(instant_demand * magnitudeExp))
    let aux2Width = clampWidth(Math.abs(instant_aux2 * magnitudeExp))
    let aux3Width = clampWidth(Math.abs(instant_aux3 * magnitudeExp))
    let auxWidth = clampWidth(Math.abs((instant_aux2 + instant_aux3) * magnitudeExp))

    const gridDisplay = friendlyFormat('instant_grid', instant_grid).join(' ')
    const solarDisplay = friendlyFormat('instant_solar', instant_solar).join(' ') // forces positive value
    const batteryDisplay = friendlyFormat('instant_battery', instant_battery).join(' ')
    const homeDisplay = friendlyFormat('instant_demand', instant_demand).join(' ') // forces positive value
    const aux2Display = friendlyFormat('instant_grid', instant_aux2).join(' ')
    const aux3Display = friendlyFormat('instant_grid', instant_aux3).join(' ')

    return (
      <>
        {instant_soc > 0 && (
          <Popup
            inverted
            content={stateOfChargeText.map((element, i) => ({ ...element, key: i }))}
            trigger={
              <div className="soc">
                {showAccurateSoc(units) && <span className={stateOfChargeTextClass}>{instant_soc}%</span>}
                <Icon className={`battery-icon ${stateOfChargeIconClass}`} name={`${stateOfChargeIcon}`} size="big" />
              </div>
            }
          />
        )}
        <Divider hidden />

        {DEBUG_NO_REFRESH === true && <Button onClick={fetchData} content="Fetch Now" />}

        {timeSinceDataMs > 20000 && retries > 3 ? <WayTooLong /> : timeSinceDataMs > 10000 && retries > 3 ? <TooLong /> : null}

        {gridClampDisconnected > 0 && (
          <Message error>
            <Message.Header>
              <>{getEnglishText('live-view : grid-clamp-diconnected-title')}</>
            </Message.Header>
            <Message.Content>
              <>{getEnglishText('live-view : grid-clamp-diconnected-body')}</>
            </Message.Content>
          </Message>
        )}

        <div className="live-view-container">
          <div className="svg-container">
            <svg height="500px" width="100%" viewBox="0 0 100 100">
              <defs>
                <linearGradient id="fadeGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stop-color="black" stop-opacity="1" />
                  <stop offset="100%" stop-color="black" stop-opacity="0" />
                </linearGradient>
              </defs>
              {/* <g style={{ display: instant_aux2 || instant_aux3 ? '' : 'none' }}>
                <path className="pv-aux-stroke" d="M50,28 v65" strokeWidth={auxWidth} />
                <path className={'flow aux' + (instant_aux2 + instant_aux3 > 0 ? ' out' : ' in')} d="M50,28 v65" strokeWidth={auxWidth} />
              </g> */}
              <g style={{ display: instant_grid ? '' : 'none' }}>
                <path className="pv-grid-stroke" d="M17,0 h22 a7,7 0 0 1 7,7 v20" strokeWidth={gridWidth} />
                <path className={'flow grid' + (instant_grid > 0 ? ' out' : ' in')} d="M17,0 h22 a7,7 0 0 1 7,7 v20" strokeWidth={gridWidth} />
              </g>
              <g style={{ display: instant_solar ? '' : 'none' }}>
                <path className="pv-solar-stroke" d="M83,0 h-22 a7,7 0 0 0 -7,7 v20" strokeWidth={solarWidth} />
                <path className={'flow solar' + (instant_solar > 0 ? ' out' : ' in')} d="M83,0 h-22 a7,7 0 0 0 -7,7 v20" strokeWidth={solarWidth} />
              </g>
              <g style={{ display: instant_battery ? '' : 'none' }}>
                <path className="pv-battery-stroke" d="M17,55 h22 a7,7 0 0 0 7,-7 v-20" strokeWidth={batteryWidth} />
                <path className={'flow battery' + (instant_battery > 0 ? ' out' : ' in')} d="M17,55 h22 a7,7 0 0 0 7,-7 v-20" strokeWidth={batteryWidth} />
              </g>
              <g style={{ display: instant_demand ? '' : 'none' }}>
                <path className="pv-home-stroke" d="M83,55 h-22 a7,7 0 0 1 -7,-7 v-20" strokeWidth={homeWidth} />
                <path className={'flow home' + (instant_demand > 0 ? ' out' : ' in')} d="M83,55 h-22 a7,7 0 0 1 -7,-7 v-20" strokeWidth={homeWidth} />
              </g>
            </svg>
          </div>

          <div className="container">
            <div className="node joint" style={{ display: isLoaded ? '' : 'none' }} />
            <div className={'node solar pv-solar-node' + (instant_solar ? '' : ' empty')}>
              <img className="solar-icon" src={solarIcon} alt="" />
              <span>{solarDisplay}</span>
            </div>
            <div
              className={
                'node grid pv-grid-node' +
                (instant_grid ? '' : ' empty') +
                (gridClampDisconnected > 0 ? ' disconnected' : '') +
                (gatewayStatus?.status && gatewayStatus.status === 'online-eps-mode' ? ' gateway-eps-mode' : '')
              }
            >
              {gridClampDisconnected > 0 && (
                <Icon
                  name="warning"
                  color="red"
                  size="large"
                  circular
                  style={{ position: 'absolute', top: '-44px', right: '-19px', backgroundColor: '#fff' }}
                />
              )}
              <img className="grid-icon" src={gridIcon} alt="" />
              <span>{gridDisplay}</span>
            </div>
            <div className={'node home pv-home-node' + (instant_demand ? '' : ' empty')}>
              <Icon name="home" size="big" />
              <span>{homeDisplay}</span>
            </div>
            <div className={'node battery pv-battery-node' + (instant_battery ? '' : ' empty')}>
              <Icon className="battery-icon" name={`${stateOfChargeIcon}`} size="big" />
              <span>{batteryDisplay}</span>
            </div>
            {(aux2Connected || aux3Connected) && (
              <div className="aux-node-container">
                {aux2Connected && (
                  <div className={'node aux aux2 pv-aux-node' + (instant_aux2 ? '' : ' empty')}>
                    {getClampInfo('aux2').image ? (
                      <img className="aux-icon" src={getClampInfo('aux2').image} alt="" />
                    ) : (
                      <Icon className="aux-icon" name={`${getClampInfo('aux2').icon}`} size="big" />
                    )}
                    <span>{aux2Display}</span>
                  </div>
                )}
                {aux3Connected && (
                  <div className={'node aux aux3 pv-aux-node' + (instant_aux3 ? '' : ' empty')}>
                    {getClampInfo('aux3').image ? (
                      <img className="aux-icon" src={getClampInfo('aux3').image} alt="" />
                    ) : (
                      <Icon className="aux-icon" name={`${getClampInfo('aux3').icon}`} size="big" />
                    )}
                    <span>{aux3Display}</span>
                  </div>
                )}
              </div>
            )}
          </div>

          {isLoaded ? '' : <Loader active inline="centered" size="large" />}
          {/* <TimeRemaining units={units} _soc={instant_soc} _solar={solarDisplay} _grid={gridDisplay} _home={homeDisplay} _battery={batteryDisplay} /> */}
        </div>
      </>
    )
  }

  return (
    <Segment id="live-view" className={`${showMore ? 'show-more' : ''}`}>
      <Header as="h2">
        <Icon name="power" color="teal" />
        <Header.Content>
          {getEnglishText('live-view : title')}&nbsp;&nbsp;
          {isLoaded && units.length > 1 && (
            <span className="clusters-desc" style={{ fontSize: clusters.length > 1 ? '75%' : '100%' }}>
              <>
                ({units.length} {getEnglishText('common : units')}
                {clusters.length > 1 && <>{`, in ${clusters.length} clusters`}</>})&nbsp;&nbsp;
              </>
            </span>
          )}
          <Header.Subheader>{getEnglishText('live-view : subtitle')}</Header.Subheader>
        </Header.Content>
      </Header>

      {error && (
        <Message negative>
          <p>
            {getEnglishText('common : error')}: {error.message}
          </p>
        </Message>
      )}

      {warning && (
        <Message warning>
          <p>{warning}</p>
        </Message>
      )}

      {!isLoaded ? (
        <Loader active inline="centered" />
      ) : (
        <>
          <VOCSensor units={units} />
          {units.length === 1 && units?.[0].gatewayConnected === 1 && <GatewayStatus units={units} onUpdate={handleGatewayUpdate} />}
          <LiveViewChart />
          <Weather unit={units[0]} />
          <ForceChargeDischarge hardwareIds={units} />
          <Divider hidden className="mobile-hidden" />
        </>
      )}

      <div className="show-more-button mobile-only" style={{ textAlign: 'right', zIndex: 9 }}>
        <Link onClick={() => setShowMore(!showMore)}>
          Show {`${showMore ? getEnglishText('common : less').toLowerCase() : getEnglishText('common : more').toLowerCase()}`} ...
        </Link>
      </div>
    </Segment>
  )
}

/////////////
// HELPERS //
/////////////

function parseAccurateSoc(units, instant_soc) {
  const { model } = units[0]
  const { modifiedSoCRange } = parseModelCode(model)

  if (modifiedSoCRange) {
    const minVal = 7 // treat 7 as 0%
    const maxVal = 90 // treat 90 as 100%

    function getModifiedPercent(value) {
      return (100 * (value - minVal)) / (maxVal - minVal)
    }

    debugMessage(`Using modified SoC range ${minVal}-${maxVal}`, 'Live View')

    let modifiedSoc = getModifiedPercent(instant_soc)
    modifiedSoc = parseInt(modifiedSoc, 10)

    if (modifiedSoc < 1) modifiedSoc = 1
    if (modifiedSoc > 100) modifiedSoc = 100

    return modifiedSoc
  }

  return parseInt(instant_soc, 10)
}

function showAccurateSoc(units) {
  const { model, displayTrueSoC } = units[0]
  if (displayTrueSoC === 1) return true
  // otherwise, check model
  const { accurateSoc } = parseModelCode(model)
  return accurateSoc
}

/////////////
// EXPORTS //
/////////////

export { LiveView }
