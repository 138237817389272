function calculatedFields(unitsData) {
  /*
      unitsData = {
        units: [ ... ],
        rawData: {
          FFR_LOCAL_ActivePower_INST_mW_5minMEAN: [
            ["2023-08-09T03:40:00Z", 12],
            ...
          ],
          ...
        }
      }
    */

  ///////////
  // DEBUG //
  ///////////

  const debug_csv = []

  function debug_addToCsv(row) {
    debug_csv.push(row)
  }

  function debug_outputCsv() {
    const headers = Object.keys(debug_csv[0])
    const rows = []

    for (let i in debug_csv) {
      const row = Object.keys(debug_csv[i]).map((k) => debug_csv[i][k])
      rows.push(row)
    }

    let csv = headers.join(',')
    for (let i in rows) csv += '\n' + rows[i].join(',')

    console.log('debug_csv', csv)
  }

  ///////////
  ///////////
  ///////////

  const calcEff = (batteryDcPower, solarDcPower, FFR_LOCAL) => {
    if (batteryDcPower > 0)
      if (!solarDcPower)
        if (FFR_LOCAL == 0) return null
        // if battery DC power > 0 and no solar, then just simple charging: FFR LOCAL = batteryDC / eff
        else return batteryDcPower / FFR_LOCAL
      // if battery DC power > 0 and solar, then FFR LOCAL = solarDC x eff + batteryDC / eff
      else
        return Math.max(
          (FFR_LOCAL + Math.sqrt(Math.pow(FFR_LOCAL, 2) - 4 * solarDcPower * batteryDcPower)) / (2 * solarDcPower),
          (FFR_LOCAL - Math.sqrt(Math.pow(FFR_LOCAL, 2) - 4 * solarDcPower * batteryDcPower)) / (2 * solarDcPower)
        )
    else if (!solarDcPower && !batteryDcPower) return null
    // if battery DC power < 0 (discharging), both battery and solar are going in the same direction: FFR LOCAL = (batteryDC + solarDC) x eff
    else return FFR_LOCAL / (batteryDcPower + solarDcPower)
  }

  const { unit, units, rawData } = unitsData

  // if not `rawData` object then there's nothing for us to do!
  if (!rawData) {
    return {
      units: units ? units : [unit],
      series: null,
      totals: unitsData.totals
    }
  }

  const firstQueryKey = Object.keys(rawData)[0]
  const firstQueryLength = rawData[firstQueryKey].length
  const returnRows = []

  const totals = {
    solarConsumed: 0,
    solarGenerated: 0,
    gridImports: 0,
    gridExports: 0,
    homeConsumed: 0,
    batteryCharge: 0,
    batteryDischarge: 0,
    batteryOutputExported: 0,
    aux2Consumed: 0,
    aux3Consumed: 0
  }

  for (let j = 0; j < firstQueryLength; j++) {
    // j = index of this unit's data results (24 hours = 288 samples)

    const time = rawData[firstQueryKey][j][0]
    const calculatedFields = {}

    ///////////
    // DEBUG //
    ///////////
    const moment = require('moment')

    const debug_csvRow = {}

    function debug_addToCsvRow(key, value) {
      debug_csvRow[key] = value
    }

    debug_addToCsvRow('time', moment(time).format('YYYY-MM-DD HH:mm:ss'))

    ///////////
    ///////////
    ///////////

    // Values are null if no result from Influx
    const FFR_LOCAL_ActivePower_INST_mW_5minMEAN = rawData['FFR_LOCAL_ActivePower_INST_mW_5minMEAN']?.[j]?.[1] ?? null
    const FFR_AUX1_ActivePower_INST_mW_5minMEAN = rawData['FFR_AUX1_ActivePower_INST_mW_5minMEAN']?.[j]?.[1] ?? null
    const FFR_AUX2_ActivePower_INST_mW_5minMEAN = rawData['FFR_AUX2_ActivePower_INST_mW_5minMEAN']?.[j]?.[1] ?? null
    const FFR_AUX3_ActivePower_INST_mW_5minMEAN = rawData['FFR_AUX3_ActivePower_INST_mW_5minMEAN']?.[j]?.[1] ?? null
    const INV_GRID_ApparentPower_INST_mVA_5minMEAN = rawData['INV_GRID_ApparentPower_INST_mVA_5minMEAN']?.[j]?.[1] ?? null
    const FFR_HOUSE_ActivePower_INST_mW_5minMEAN = rawData['FFR_HOUSE_ActivePower_INST_mW_5minMEAN']?.[j]?.[1] ?? null
    const INV_PV1_ActivePower_INST_mW_5minMEAN = rawData['INV_PV1_ActivePower_INST_mW_5minMEAN']?.[j]
      ? -rawData['INV_PV1_ActivePower_INST_mW_5minMEAN'][j][1]
      : null
    const INV_PV2_ActivePower_INST_mW_5minMEAN = rawData['INV_PV2_ActivePower_INST_mW_5minMEAN']?.[j]
      ? -rawData['INV_PV2_ActivePower_INST_mW_5minMEAN'][j][1]
      : null
    const Pack0_DcCurrent_INST_mA_5minMEAN = rawData['Pack0_DcCurrent_INST_mA_5minMEAN']?.[j]?.[1] ?? null
    const Pack1_DcCurrent_INST_mA_5minMEAN = rawData['Pack1_DcCurrent_INST_mA_5minMEAN']?.[j]?.[1] ?? null
    const Pack2_DcCurrent_INST_mA_5minMEAN = rawData['Pack2_DcCurrent_INST_mA_5minMEAN']?.[j]?.[1] ?? null
    const Pack3_DcCurrent_INST_mA_5minMEAN = rawData['Pack3_DcCurrent_INST_mA_5minMEAN']?.[j]?.[1] ?? null
    const Pack4_DcCurrent_INST_mA_5minMEAN = rawData['Pack4_DcCurrent_INST_mA_5minMEAN']?.[j]?.[1] ?? null
    const Pack5_DcCurrent_INST_mA_5minMEAN = rawData['Pack5_DcCurrent_INST_mA_5minMEAN']?.[j]?.[1] ?? null
    const Pack6_DcCurrent_INST_mA_5minMEAN = rawData['Pack6_DcCurrent_INST_mA_5minMEAN']?.[j]?.[1] ?? null
    const Pack7_DcCurrent_INST_mA_5minMEAN = rawData['Pack7_DcCurrent_INST_mA_5minMEAN']?.[j]?.[1] ?? null
    const Pack8_DcCurrent_INST_mA_5minMEAN = rawData['Pack8_DcCurrent_INST_mA_5minMEAN']?.[j]?.[1] ?? null
    const Pack9_DcCurrent_INST_mA_5minMEAN = rawData['Pack9_DcCurrent_INST_mA_5minMEAN']?.[j]?.[1] ?? null
    const Pack0_DcVoltage_INST_mV_5minMEAN = rawData['Pack0_DcVoltage_INST_mV_5minMEAN']?.[j]?.[1] ?? null
    const Pack1_DcVoltage_INST_mV_5minMEAN = rawData['Pack1_DcVoltage_INST_mV_5minMEAN']?.[j]?.[1] ?? null
    const Pack2_DcVoltage_INST_mV_5minMEAN = rawData['Pack2_DcVoltage_INST_mV_5minMEAN']?.[j]?.[1] ?? null
    const Pack3_DcVoltage_INST_mV_5minMEAN = rawData['Pack3_DcVoltage_INST_mV_5minMEAN']?.[j]?.[1] ?? null
    const Pack4_DcVoltage_INST_mV_5minMEAN = rawData['Pack4_DcVoltage_INST_mV_5minMEAN']?.[j]?.[1] ?? null
    const Pack5_DcVoltage_INST_mV_5minMEAN = rawData['Pack5_DcVoltage_INST_mV_5minMEAN']?.[j]?.[1] ?? null
    const Pack6_DcVoltage_INST_mV_5minMEAN = rawData['Pack6_DcVoltage_INST_mV_5minMEAN']?.[j]?.[1] ?? null
    const Pack7_DcVoltage_INST_mV_5minMEAN = rawData['Pack7_DcVoltage_INST_mV_5minMEAN']?.[j]?.[1] ?? null
    const Pack8_DcVoltage_INST_mV_5minMEAN = rawData['Pack8_DcVoltage_INST_mV_5minMEAN']?.[j]?.[1] ?? null
    const Pack9_DcVoltage_INST_mV_5minMEAN = rawData['Pack9_DcVoltage_INST_mV_5minMEAN']?.[j]?.[1] ?? null
    const INV_BATTERY_DcCapacity_INST_pct_5minMEAN = rawData['INV_BATTERY_DcCapacity_INST_pct_5minMEAN']?.[j]?.[1] ?? null
    const SafetyCheck_UsableStateOfCharge_INST_pct_5minMEAN = rawData['SafetyCheck_UsableStateOfCharge_INST_pct_5minMEAN']?.[j]?.[1] ?? null
    const M4_undefined_StateOfCharge_INST_pct_5minMEAN = rawData['M4_undefined_StateOfCharge_INST_pct_5minMEAN']?.[j]?.[1] ?? null
    const Pylontech_undefined_StateOfCharge_INST_pct_5minMEAN = rawData['Pylontech_undefined_StateOfCharge_INST_pct_5minMEAN']?.[j]?.[1] ?? null
    const HealthCheck_Network_Ping_Google__5minMEAN = rawData['HealthCheck_Network_Ping_Google_%_5minMEAN']?.[j]?.[1] ?? null
    const FFR_FAULT_HOUSECLAMPDISCONNECTED_INST_NONE = rawData['FFR_FAULT_HOUSECLAMPDISCONNECTED_INST_NONE']?.[j]?.[1] ?? null
    const INV_LOGIC_DNOACTIVATED_INST_NONE = rawData['INV_LOGIC_DNOACTIVATED_INST_NONE']?.[j]?.[1] ?? null

    const EPS_EPS_GRIDLOSS_INST_NONE = rawData['EPS_EPS_GRIDLOSS_INST_NONE']?.[j]?.[1] ?? null
    const EPS_EPS_GridLossOverConsumption_INST_NONE = rawData['EPS_EPS_GridLossOverConsumption_INST_NONE']?.[j]?.[1] ?? null
    const EPS_EPS_GRIDLOSSLOWSOC_INST_NONE = rawData['EPS_EPS_GRIDLOSSLOWSOC_INST_NONE']?.[j]?.[1] ?? null
    const SDM_HOUSE_ActivePower_INST_mW = rawData['SDM_HOUSE_ActivePower_INST_mW']?.[j]?.[1] ?? null

    ///////////////////////////////////
    // Create calculated fields here //
    // vvvvvvvvvvvvvvvvvvvvvvvvvvvvv //

    // String current = average of pack currents
    const String0_DcCurrent =
      (Pack0_DcCurrent_INST_mA_5minMEAN == null) | (Pack1_DcCurrent_INST_mA_5minMEAN == null)
        ? null
        : (Pack0_DcCurrent_INST_mA_5minMEAN + Pack1_DcCurrent_INST_mA_5minMEAN) / 2
    const String1_DcCurrent =
      (Pack2_DcCurrent_INST_mA_5minMEAN == null) | (Pack3_DcCurrent_INST_mA_5minMEAN == null)
        ? null
        : (Pack2_DcCurrent_INST_mA_5minMEAN + Pack3_DcCurrent_INST_mA_5minMEAN) / 2
    const String2_DcCurrent =
      (Pack4_DcCurrent_INST_mA_5minMEAN == null) | (Pack5_DcCurrent_INST_mA_5minMEAN == null)
        ? null
        : (Pack4_DcCurrent_INST_mA_5minMEAN + Pack5_DcCurrent_INST_mA_5minMEAN) / 2
    const String3_DcCurrent =
      (Pack6_DcCurrent_INST_mA_5minMEAN == null) | (Pack7_DcCurrent_INST_mA_5minMEAN == null)
        ? null
        : (Pack6_DcCurrent_INST_mA_5minMEAN + Pack7_DcCurrent_INST_mA_5minMEAN) / 2
    const String4_DcCurrent =
      (Pack8_DcCurrent_INST_mA_5minMEAN == null) | (Pack9_DcCurrent_INST_mA_5minMEAN == null)
        ? null
        : (Pack8_DcCurrent_INST_mA_5minMEAN + Pack9_DcCurrent_INST_mA_5minMEAN) / 2
    // String voltage = sum of pack voltages
    const String0_DcVoltage =
      (Pack0_DcVoltage_INST_mV_5minMEAN == null) | (Pack1_DcVoltage_INST_mV_5minMEAN == null)
        ? null
        : Pack0_DcVoltage_INST_mV_5minMEAN + Pack1_DcVoltage_INST_mV_5minMEAN
    const String1_DcVoltage =
      (Pack2_DcVoltage_INST_mV_5minMEAN == null) | (Pack3_DcVoltage_INST_mV_5minMEAN == null)
        ? null
        : Pack2_DcVoltage_INST_mV_5minMEAN + Pack3_DcVoltage_INST_mV_5minMEAN
    const String2_DcVoltage =
      (Pack4_DcVoltage_INST_mV_5minMEAN == null) | (Pack5_DcVoltage_INST_mV_5minMEAN == null)
        ? null
        : Pack4_DcVoltage_INST_mV_5minMEAN + Pack5_DcVoltage_INST_mV_5minMEAN
    const String3_DcVoltage =
      (Pack6_DcVoltage_INST_mV_5minMEAN == null) | (Pack7_DcVoltage_INST_mV_5minMEAN == null)
        ? null
        : Pack6_DcVoltage_INST_mV_5minMEAN + Pack7_DcVoltage_INST_mV_5minMEAN
    const String4_DcVoltage =
      (Pack8_DcVoltage_INST_mV_5minMEAN == null) | (Pack9_DcVoltage_INST_mV_5minMEAN == null)
        ? null
        : Pack8_DcVoltage_INST_mV_5minMEAN + Pack9_DcVoltage_INST_mV_5minMEAN
    // String power = string current x string voltage
    const String0_DcPower = (String0_DcCurrent == null) | (String0_DcVoltage == null) ? null : (String0_DcCurrent * String0_DcVoltage) / 1000
    const String1_DcPower = (String1_DcCurrent == null) | (String1_DcVoltage == null) ? null : (String1_DcCurrent * String1_DcVoltage) / 1000
    const String2_DcPower = (String2_DcCurrent == null) | (String2_DcVoltage == null) ? null : (String2_DcCurrent * String2_DcVoltage) / 1000
    const String3_DcPower = (String3_DcCurrent == null) | (String3_DcVoltage == null) ? null : (String3_DcCurrent * String3_DcVoltage) / 1000
    const String4_DcPower = (String4_DcCurrent == null) | (String4_DcVoltage == null) ? null : (String4_DcCurrent * String4_DcVoltage) / 1000

    debug_addToCsvRow('String0_DcPower', String0_DcPower)
    debug_addToCsvRow('String1_DcPower', String1_DcPower)
    debug_addToCsvRow('String2_DcPower', String2_DcPower)
    debug_addToCsvRow('String3_DcPower', String3_DcPower)
    debug_addToCsvRow('String4_DcPower', String4_DcPower)

    // Total DC power is sum of strings' power
    let batteryDcPower = null
    if (String0_DcPower != null || String1_DcPower != null || String2_DcPower != null || String3_DcPower != null || String4_DcPower != null) {
      batteryDcPower = (String0_DcPower || 0) + (String1_DcPower || 0) + (String2_DcPower || 0) + (String3_DcPower || 0) + (String4_DcPower || 0)
      debug_addToCsvRow('batteryDcPower', batteryDcPower)
    }

    // DC power reported from inverter (when inverter connected in DC)
    let solarDcPower = null
    if (INV_PV1_ActivePower_INST_mW_5minMEAN != null || INV_PV2_ActivePower_INST_mW_5minMEAN != null)
      solarDcPower = (INV_PV1_ActivePower_INST_mW_5minMEAN || 0) + (INV_PV2_ActivePower_INST_mW_5minMEAN || 0)

    // If battery AC power is > 0 but battery DC power < 0, I'm assuming that battery AC power is just a small value and corresponds to idle consumption of the unit
    let batteryIdleConsumption = null
    if (batteryDcPower != null)
      batteryIdleConsumption = FFR_LOCAL_ActivePower_INST_mW_5minMEAN > 0 && batteryDcPower <= 0 ? FFR_LOCAL_ActivePower_INST_mW_5minMEAN : 0
    else batteryIdleConsumption = FFR_LOCAL_ActivePower_INST_mW_5minMEAN > 0 && batteryDcPower < 0 ? FFR_LOCAL_ActivePower_INST_mW_5minMEAN : 0

    debug_addToCsvRow('batteryIdleConsumption', batteryIdleConsumption)

    // Initialised as null
    let batteryAll = null
    let solarAll = null
    calculatedFields.batteryInputFromGrid = null
    calculatedFields.batteryInputFromSolar = null
    calculatedFields.batteryOutputConsumedByHome = null
    calculatedFields.batteryOutputExported = null
    calculatedFields.homeConsumed = null
    calculatedFields.gridConsumedByHome = null
    calculatedFields.solarConsumedByHome = null
    calculatedFields.solarExported = null
    calculatedFields.instant_battery = null
    calculatedFields.instant_demand = null
    calculatedFields.instant_grid = null
    calculatedFields.solarGenerated = null
    calculatedFields.solarConsumption = null
    calculatedFields.instant_solar = null
    calculatedFields.instant_soc = null
    calculatedFields.dnoOverride = null
    calculatedFields.accessoryClamp1 = null
    calculatedFields.smartHomeDevices = null

    if (FFR_LOCAL_ActivePower_INST_mW_5minMEAN != null && FFR_HOUSE_ActivePower_INST_mW_5minMEAN != null) {
      debug_addToCsvRow('FFR_HOUSE_ActivePower_INST_mW_5minMEAN', FFR_HOUSE_ActivePower_INST_mW_5minMEAN)
      debug_addToCsvRow('FFR_LOCAL_ActivePower_INST_mW_5minMEAN', FFR_LOCAL_ActivePower_INST_mW_5minMEAN)
      debug_addToCsvRow('FFR_AUX1_ActivePower_INST_mW_5minMEAN', FFR_AUX1_ActivePower_INST_mW_5minMEAN)

      if (!solarDcPower) {
        // Simple (and most common case): no DC solar power
        // battery power is FFR LOCAL - idle consumption
        batteryAll = FFR_LOCAL_ActivePower_INST_mW_5minMEAN - batteryIdleConsumption

        debug_addToCsvRow('batteryAll', batteryAll)

        // solar is just FFR AUX1 (changed sign because FFR AUX1 is negative when generating)
        solarAll = -FFR_AUX1_ActivePower_INST_mW_5minMEAN
      } else {
        // DC units case. In this case we are trying to split FFR LOCAL into the contribution from solar and that from the battery
        // So we try to calculate the AC-equivalent solar and battery power (which is a bit abstract as everything goes through the inverter together, but it's needed for representation on the portal)
        const ffrLocalActual = FFR_LOCAL_ActivePower_INST_mW_5minMEAN - batteryIdleConsumption
        let efficiencyDcAc = null
        if (batteryDcPower != null) {
          // calculate AC/DC efficiency
          efficiencyDcAc = calcEff(batteryDcPower, solarDcPower, ffrLocalActual)
          if (efficiencyDcAc == null) {
            // this is the case if solar DC is null and battery DC or FFR LOCAL are null
            // everything is zero except if there is a separate AC solar (with a separate inverter) as well
            solarAll = FFR_AUX1_ActivePower_INST_mW_5minMEAN != null ? -FFR_AUX1_ActivePower_INST_mW_5minMEAN : null
            batteryAll = 0
            debug_addToCsvRow('batteryAll', batteryAll)
          } else {
            // solarDC x efficiency + solarAC (if any, generally it's either DC or AC)
            // There is a - sign because both solarDcPower and FFR_AUX1 are negative when generating
            solarAll = -solarDcPower * efficiencyDcAc - FFR_AUX1_ActivePower_INST_mW_5minMEAN
            if (batteryDcPower <= 0) {
              // Discharge case
              batteryAll = batteryDcPower * efficiencyDcAc
              debug_addToCsvRow('batteryAll', batteryAll)

              // Charge case
            } else {
              batteryAll = batteryDcPower / efficiencyDcAc
              debug_addToCsvRow('batteryAll', batteryAll)
            }
          }
        } else {
          // if battery DC power is null, can't do the calculations
          batteryAll = null
          solarAll = null
        }
      }

      if (batteryAll != null && solarAll != null) {
        // Pure house demand = Grid imports - Grid exports + Battery discharge - Battery charge + Solar generated - Solar (inverter) consumption
        calculatedFields.homeConsumed = Math.max(
          0,
          FFR_HOUSE_ActivePower_INST_mW_5minMEAN - FFR_LOCAL_ActivePower_INST_mW_5minMEAN - FFR_AUX1_ActivePower_INST_mW_5minMEAN
        )
        // Solar generation (solarAll when positive)
        calculatedFields.solarGenerated = Math.max(0, solarAll)
        // Solar inverter consumption (solarAll when negative)
        calculatedFields.solarConsumption = Math.max(0, -solarAll)
        // Battery discharge (batteryAll when negative)
        const batteryOutput = Math.max(0, -batteryAll)
        // Portion of battery discharge that is exported to the grid (min between discharge and grid exports)
        calculatedFields.batteryOutputExported = Math.min(batteryOutput, Math.max(0, -FFR_HOUSE_ActivePower_INST_mW_5minMEAN))
        // The remaining portion of battery discharge is used to supply the house
        calculatedFields.batteryOutputConsumedByHome = batteryOutput - calculatedFields.batteryOutputExported
        // Battery charge (batteryAll when positive)
        const batteryInput = Math.max(0, batteryAll)

        debug_addToCsvRow('batteryInput', batteryInput)

        // Portion of battery charge that is taken from the grid (min between charge and grid imports)
        calculatedFields.batteryInputFromGrid = Math.min(batteryInput, Math.max(0, FFR_HOUSE_ActivePower_INST_mW_5minMEAN))

        debug_addToCsvRow('calculatedFields.batteryInputFromGrid', calculatedFields.batteryInputFromGrid)

        // The remaining portion of battery charge is taken from the solar generation
        calculatedFields.batteryInputFromSolar = batteryInput - calculatedFields.batteryInputFromGrid

        debug_addToCsvRow('calculatedFields.batteryInputFromSolar', calculatedFields.batteryInputFromSolar)

        // Grid imports that are not used to charge the battery are used to supply the house
        calculatedFields.gridConsumedByHome = Math.max(0, FFR_HOUSE_ActivePower_INST_mW_5minMEAN) - calculatedFields.batteryInputFromGrid
        // Portion of solar that is used to supply the house (min between solar generated and house demand)
        calculatedFields.solarConsumedByHome = Math.min(calculatedFields.solarGenerated, calculatedFields.homeConsumed)
        // The portion of grid exports that are not due to the battery discharging are due to solar generation
        calculatedFields.solarExported = Math.max(0, -FFR_HOUSE_ActivePower_INST_mW_5minMEAN) - calculatedFields.batteryOutputExported

        // Fields for live view
        // In live view +ve means going out of the icon, -ve means going towards the icon
        // Battery flow should be positive when discharging and negative when charging (opposite of batteryAll). batteryIdleConsumption is added back so all flows add up to zero
        calculatedFields.instant_battery = -batteryAll - batteryIdleConsumption
        // House demand made negative so the direction in the live view goes towards the house icon
        calculatedFields.instant_demand = -calculatedFields.homeConsumed
        // Solar generation should be positive
        calculatedFields.instant_solar = solarAll
        // Grid imports are positive, exports negative (as per FFR HOUSE)
        calculatedFields.instant_grid = FFR_HOUSE_ActivePower_INST_mW_5minMEAN

        // SOC
        // use usable SOC measurement (rev18 only) or P3/P3X measurement (rev17 or P3X-alpha release) based on which value is present; if none, use inverter measurement (this shouldn't really happen)
        calculatedFields.instant_soc = SafetyCheck_UsableStateOfCharge_INST_pct_5minMEAN

        calculatedFields.hasInternetAccess = HealthCheck_Network_Ping_Google__5minMEAN

        calculatedFields.instant_aux2 = FFR_AUX2_ActivePower_INST_mW_5minMEAN
        calculatedFields.instant_aux3 = FFR_AUX3_ActivePower_INST_mW_5minMEAN
      }
    }

    calculatedFields.gridClampDisconnected = FFR_FAULT_HOUSECLAMPDISCONNECTED_INST_NONE
    calculatedFields.dnoOverride = INV_LOGIC_DNOACTIVATED_INST_NONE
    calculatedFields.epsGridLoss = EPS_EPS_GRIDLOSS_INST_NONE
    calculatedFields.epsOverConsumption = EPS_EPS_GridLossOverConsumption_INST_NONE
    calculatedFields.epsLowSoC = EPS_EPS_GRIDLOSSLOWSOC_INST_NONE
    calculatedFields.accessoryClamp1 = SDM_HOUSE_ActivePower_INST_mW

    // find and group Smart Home Devices results
    let smartHomeDevices = {}
    // for (let n in rawData) {
    //   if (n.includes('SmartHome_Device_')) {
    //     value = rawData[n].filter((row) => row[0] === time)
    //     try {
    //       smartHomeDevices[n] = value[0][1]
    //     } catch (err) {
    //       smartHomeDevices[n] = null
    //     }
    //   }
    // }
    calculatedFields.smartHomeDevices = smartHomeDevices

    // solarConsumed here is defined as the portion of solar generation that is consumed by the house (i.e. not exported to the grid)
    // (not to be confused with the above solarConsumption, which is the solar inverter consumption, and is not displayed anywhere in the portal)
    // all calculatedFields measurements are 5m-avg power measurements in mW - so we multiply by 5[min] / 60[min/hour] to get mWh
    totals.solarConsumed += ((calculatedFields.solarGenerated - calculatedFields.solarExported) * 5) / 60
    totals.solarGenerated += (calculatedFields.solarGenerated * 5) / 60
    totals.gridImports += (Math.max(0, FFR_HOUSE_ActivePower_INST_mW_5minMEAN) * 5) / 60
    totals.gridExports += (Math.max(0, -FFR_HOUSE_ActivePower_INST_mW_5minMEAN) * 5) / 60
    totals.homeConsumed += (calculatedFields.homeConsumed * 5) / 60
    totals.batteryCharge += (Math.max(0, batteryAll) * 5) / 60
    totals.batteryDischarge += (Math.max(0, -batteryAll) * 5) / 60
    totals.aux2Consumed += (calculatedFields.instant_aux2 * 5) / 60
    totals.aux3Consumed += (calculatedFields.instant_aux3 * 5) / 60

    // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ //
    // Create calculated fields here //
    ///////////////////////////////////

    const row = {
      time,
      ...calculatedFields
    }

    returnRows.push(row)
    debug_addToCsv(debug_csvRow)
  }

  // debug_outputCsv()

  return {
    units: units ? units : [unit],
    series: returnRows,
    totals
  }

  /*
      {
        series: [
          {
            time: '2023-08-07T23:00:00Z',
            batteryInputFromGrid: 0,
            batteryInputFromSolar: 0,
            batteryOutputConsumedByHome: 0,
            batteryOutputExported: 0,
            homeConsumed: 3283291.9,
            gridConsumedByHome: 3362966.6666666665,
            solarConsumedByHome: 0,
            solarExported: 0,
            instant_battery: -60824.76666666667,
            instant_demand: -3283291.9,
            instant_grid: 3362966.6666666665,
            solarGenerated: 0,
            solarConsumption: 18850,
            instant_solar: -18850,
            instant_soc: 3.31,
            dnoOverride: null,
            accessoryClamp1: null,
            smartHomeDevices: null, // don't worry about this for now, set to `null
            hasInternetAccess: null,
            gridClampDisconnected: null,
            epsGridLoss: 0,
            epsOverConsumption: null,
            epsLowSoC: null,
            hardwareId: '12345677',
            isNow: false,
            missingData: false,
            noInternet: true
          },
          ... up to 288 in this array max (24 hours of 5 minutely data)
        ],
        totals: {
          batteryCharge: 12892030.18968059,
          batteryDischarge: 10079013.324319303,
          gridExports: 0,
          gridImports: 36822851.756799154,
          homeConsumed: 37427864.0767825,
          solarConsumed: 11687163.175128136,
          solarGenerated: 11687163.175128136
        }
      }
    */
}

export { calculatedFields }
