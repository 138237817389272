import moment from 'moment'
import { parseModelCode } from './lib/modelCodeParser'
import { parseFirmwareString } from './lib/firmwareStringParser'
import { authenticator } from './authenticator'
import { getEnglishText } from './dictionary'
import config from './config'

async function cleanup({ clearCache = false, signIn = false, signOut = false, redirect = null, reload = false, initiator = 'UNKNOWN `initiator`' }) {
  debugMessage(`${initiator} called cleanup()`)

  if (clearCache) {
    debugMessage('Clearing cache ...', 'cleanup()')

    /////////////////////////////////////////////////////////////
    // copy any `action` item for preservation after cache clear!
    const protectedItem = window.localStorage.getItem('action')
    if (protectedItem) {
      console.log('Action', 'Protected `action` found', protectedItem)
    }
    /////////////////////////////////////////////////////////////

    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach(async (name) => {
          console.log('name', name)
          await caches.delete(name)
        })
      })
    }
    window.localStorage.clear()

    ////////////////////////////////////////////////////
    // recover protected `action` item
    if (protectedItem) {
      window.localStorage.setItem('action', protectedItem)
      console.log('Action', 'Recovered protected `action` item in localstorage')
      console.log('Action', 'protectedItem', protectedItem)
    }
    ////////////////////////////////////////////////////
  }

  if (signOut) {
    debugMessage('authenticator.signOut()', 'cleanup()')
    await authenticator.signOut()
    // debugMessage('Redirecting to /', 'cleanup()')
    // window.location = '/'
  }

  if (signIn) {
    debugMessage('Redirecting to /login', 'cleanup()')
    await authenticator.signIn()
    // window.location = '/login'
  }

  if (redirect) {
    debugMessage(`Redirecting to ${redirect} ...`, 'cleanup()')
    window.location = redirect
  }

  if (reload) {
    debugMessage('window.location.reload()', 'cleanup()')
    window.location.reload(true)
  }
}

function getPaymentGatewayUrl() {
  switch (process.env.REACT_APP_PAYMENT_GATEWAY_ENV) {
    case 'dev':
    case 'local':
      return 'payments-dev.powervault.co.uk'
    case 'test':
      return 'payments-test.powervault.co.uk'
    case 'staging':
    case 'prod':
    default:
      return 'payments.powervault.co.uk'
  }
}

function launchAccountPayment({ customerAccountId, productId }) {
  window.location.href = `https://${getPaymentGatewayUrl()}/?productId=${productId}&accountId=${customerAccountId}&hash=_`
}

function launchUnitPayment({ customerAccountId, configId, productId }) {
  window.location.href = `https://${getPaymentGatewayUrl()}/?productId=${productId}&accountId=${customerAccountId}&configId=${configId}&hash=_`
}

const debugMessage = (message, component = '') => {
  if (process.env.REACT_APP_DEBUG == 'true' || window.location.host === 'localhost') {
    if (component) console.log(`DEBUG - ${component} ::`, message)
    else console.log(`DEBUG - ${message}`)
  }
}

const friendlyFormat = (key, value) => {
  // returns [value, unit]

  function formatMicroPowerValue(value, suffix = '') {
    let buffer = value / 1000000 // convert to kW
    let unit = 'kW'

    function addCommas(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    if (buffer > 1000) {
      buffer = buffer / 1000
      unit = 'MW'
    }

    buffer = Number(buffer.toPrecision(3)) // to 3 significant figures
    if (buffer < 100) buffer = buffer.toFixed(1) // to 1 decimal place if below 100
    else buffer = buffer.toFixed(0) // remove decimals if over 100
    buffer = addCommas(buffer) // add commas

    return [buffer, `${unit}${suffix}`]
  }

  if (value === null || value === undefined || isNaN(value)) return [value, null]

  switch (key) {
    case 'batteryType':
      switch (value) {
        case 'eco':
          return ['Powervault 3 ECO', null]
        case 'amita':
          return ['Powervault 3', null]
        default:
          return [value, null]
      }
    case 'batteryCapacity':
      return [value, 'kWh']

    case 'instant_grid':
    case 'instant_battery':
      return formatMicroPowerValue(value)
    case 'instant_solar':
    case 'instant_demand':
      return formatMicroPowerValue(Math.abs(value))

    case 'instant_soc':
      return [parseInt(value, 10), '%']

    case 'chart_solar_consumed_by_home':
    case 'chart_battery_output_consumed_by_home':
    case 'chart_grid_consumed_by_home':
    case 'chart_battery_input_from_solar':
    case 'chart_solar_exported':
    case 'chart_accessory_clamp':
    case 'generic_mW':
      return [roundToFixed(value / 1000000, 1), 'kW']

    case 'chart_battery_input_from_grid':
      if (value < 100000) return [0, 'kW']
      else return [roundToFixed(value / 1000000, 1), 'kW']

    case 'daily_total_solar_generated':
    case 'daily_total_solar_consumed':
    case 'daily_total_grid_import':
    case 'daily_total_grid_export':
    case 'daily_total_home_consumed':
    case 'daily_total_battery_discharged':
      return formatMicroPowerValue(value, 'h')

    case 'daily_total_aux_consumed':
      return formatMicroPowerValue(value, 'h')

    case 'installationDateUtc':
      return [moment(value, 'x').format('LLL'), null]

    case 'warrantyActivationDate':
      return [moment(value).format('YYYY-MM-DD'), null]

    default:
      return [value, null]
  }
}

const isJson = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const queryString = (string) => {
  const keyValues = {}
  let testString = string || window.location.search
  if (testString.substr(0, 1) === '?') testString = testString.substr(1)

  testString.split('&').forEach((element) => {
    const key = element.split('=')[0]
    let value = element.split('=')[1]
    try {
      value = decodeURI(value)
      value = JSON.parse(value)
    } catch (err) {}
    if (key) keyValues[key] = value
  })

  return keyValues
}

const roundTo = (value, places) => {
  return Math.round(value * Math.pow(10, places)) / Math.pow(10, places)
}

const roundToFixed = (value, places) => {
  return value.toFixed(places)
}

const roundDownToFixed = (value, places) => {
  return Math.floor(value).toFixed(places)
}

const getChangeLog = () => {
  return new Promise((resolve, reject) => {
    fetch('/changelog.json')
      .then((res) => res.json())
      .then((data) => {
        resolve(data)
      })
      .catch((err) => reject(err))
  })
}

const nudgeTime = () => {
  const length = 32
  const pool = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let randomId = ''
  for (var i = 0; i < length; i++) randomId += pool.charAt(Math.floor(Math.random() * pool.length))
  return `${moment().format('x')}_${randomId}`
}

const deDupeUnitList = (unitList) => {
  const deDupeList = {}

  unitList.forEach((unit) => {
    if (!unit) return

    // key = "12345678_EDF Energy"
    // or key = "12345678_owner"
    // or key = "12345678"
    const key = unit.groupName ? `${unit.unitId}_${unit.groupName}` : unit.relationship ? `${unit.unitId}_${unit.relationship}` : unit.unitId

    if (!deDupeList[key]) {
      // unit with this key isn't in dudupe list, so add it
      deDupeList[key] = { ...unit }
    } else {
      // unit with this key is already in dudupe list, so compare it's registrration date
      if (parseInt(unit.registrationDateUtc, 10) > parseInt(deDupeList[key].registrationDateUtc, 10)) {
        deDupeList[key] = { ...unit }
      }
    }
  })

  return Object.keys(deDupeList).map((key) => deDupeList[key])
}

function isScheduleSet(schedule, specificDay = false) {
  if (!schedule || typeof schedule !== 'object') return false

  if (specificDay) {
    if (typeof schedule[specificDay] !== 'object') return false
    if (!schedule[specificDay].length) return false
    return true
  }

  if (typeof schedule.monday !== 'object') return false
  if (typeof schedule.tuesday !== 'object') return false
  if (typeof schedule.wednesday !== 'object') return false
  if (typeof schedule.thursday !== 'object') return false
  if (typeof schedule.friday !== 'object') return false
  if (typeof schedule.saturday !== 'object') return false
  if (typeof schedule.sunday !== 'object') return false

  if (
    !schedule.monday.length &&
    !schedule.tuesday.length &&
    !schedule.wednesday.length &&
    !schedule.thursday.length &&
    !schedule.friday.length &&
    !schedule.saturday.length &&
    !schedule.sunday.length
  )
    return false

  return true
}

function isScheduleDayGapless(schedule, specificDay) {
  // schedule[specificDay]: [
  //   {
  //       "state": "force-charge",
  //       "start": "14:30:00",
  //       "end": "17:00:00"
  //   },
  //   {
  //       "state": "only-discharge",
  //       "start": "17:00:00",
  //       "end": "18:30:00"
  //   }
  // ]

  if (!schedule || typeof schedule !== 'object') return false
  if (typeof schedule[specificDay] !== 'object') return false
  if (!schedule[specificDay].length) return false

  function slotsCoverWholeDay(slots) {
    // Sort the slots by their start times.
    slots.sort((a, b) => a.start.localeCompare(b.start))

    // Initialize a variable to keep track of the end time of the previous slot.
    let previousEnd = '00:00:00'

    // Iterate through the sorted slots array.
    for (const slot of slots) {
      const { start, end } = slot

      // Convert start and end times to Date objects for comparison.
      const startTime = new Date(`1970-01-01T${start}`)
      const endTime = new Date(`1970-01-01T${end}`)
      const previousEndTime = new Date(`1970-01-01T${previousEnd}`)

      // Check if there is a gap between the current slot and the previous slot.
      if (startTime > previousEndTime) {
        return false // There is a gap.
      }

      // Update the previousEnd variable with the end time of the current slot.
      previousEnd = end > previousEnd ? end : previousEnd
    }

    // Check if the last slot ends at or after "23:59:59".
    return previousEnd >= '23:59:59'
  }

  return slotsCoverWholeDay(schedule[specificDay])
}

function friendlyNameFromScheduleState(state) {
  switch (state) {
    case 'only-charge':
      return getEnglishText('operating-states : only-charge')
    case 'force-charge':
      return getEnglishText('operating-states : force-charge')
    case 'only-discharge':
      return getEnglishText('operating-states : only-discharge')
    case 'force-discharge':
      return getEnglishText('operating-states : force-discharge')
    case 'normal':
      return getEnglishText('operating-states : normal')
    case 'dormant':
      return getEnglishText('operating-states : dormant')
    case 'disable':
      return getEnglishText('operating-states : disabled')
    case 'ffr-high':
      return getEnglishText('operating-states : ffr-high')
    case 'ffr-low':
      return getEnglishText('operating-states : ffr-low')
    case 'ffr-prep-high':
      return getEnglishText('operating-states : ffr-prep-high')
    case 'ffr-prep-low':
      return getEnglishText('operating-states : ffr-prep-low')
    case 'eps-enabled':
      return getEnglishText('operating-states : eps-enabled')
    case 'eps-disabled':
      return getEnglishText('operating-states : eps-disabled')
    default:
      return getEnglishText('operating-states : normal')
  }
}

function isValidEmailAddress(email) {
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  return regex.test(email)
}

function readLocalStorageUnitData() {
  try {
    // const parsed = JSON.parse(localStorage.getItem('UNIT_DATA'))['units']
    // const { configId } = parsed[0]
    return JSON.parse(localStorage.getItem('UNIT_DATA'))['units']
  } catch (error) {
    // cleanup({ clearCache: true, signIn: true, reload: true, initiator: 'readLocalStorageUnitData()' })
    return {}
  }
}

export {
  config,
  parseModelCode,
  parseFirmwareString,
  friendlyFormat,
  isJson,
  queryString,
  roundTo,
  getChangeLog,
  nudgeTime,
  deDupeUnitList,
  isScheduleSet,
  isScheduleDayGapless,
  friendlyNameFromScheduleState,
  isValidEmailAddress,
  debugMessage,
  moment,
  getPaymentGatewayUrl,
  launchAccountPayment,
  launchUnitPayment,
  cleanup,
  readLocalStorageUnitData
}
