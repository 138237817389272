import React, { useState, useEffect } from 'react'
import { ajax } from '../../ajax'
import { moment, parseModelCode } from '../../common'
import { Label, Icon, Popup } from 'semantic-ui-react'

import './VOCSensor.css'

const UPDATE_INTERVAL_MS = 5000
const FRESH_DATA_THRESHOLD_SECONDS = 60 * 5 // 5 minutes
let timeoutHandle = null

function VOCSensor({ units }) {
  const [sensorStatuses, setSensorStatuses] = useState([])

  useEffect(() => {
    return () => clearTimeout(timeoutHandle)
  }, [])

  useEffect(() => {
    const unitsWithSensors = units.filter(({ model }) => parseModelCode(model).vocSensorFitted)
    if (unitsWithSensors.length > 0) {
      fetchData(unitsWithSensors)
    } else {
      setSensorStatuses([])
      clearTimeout(timeoutHandle)
    }
    // }
  }, [units])

  async function fetchData(unitsWithSensors) {
    const statuses = await Promise.all(
      unitsWithSensors.map(async (unit) => {
        const response = await ajax.fetchLastMeasurement({
          hardwareId: unit.hardwareId,
          measurementName: 'vocSensorConnected'
        })
        const lastDataTime = response?.lastData?.vocSensorConnected[0]
        const dataIsFresh = moment.utc(lastDataTime).isAfter(moment.utc().subtract(FRESH_DATA_THRESHOLD_SECONDS, 'seconds'))

        if (!dataIsFresh) return { hardwareId: unit.hardwareId, status: 'disconnected' }

        const vocValue = response?.lastData?.vocSensorConnected[1]
        return {
          hardwareId: unit.hardwareId,
          status: vocValue ? 'online' : 'disconnected'
        }
      })
    )

    setSensorStatuses(statuses)

    clearTimeout(timeoutHandle)
    timeoutHandle = setTimeout(() => fetchData(unitsWithSensors), UPDATE_INTERVAL_MS)
  }

  const onlineCount = sensorStatuses.filter((sensor) => sensor.status === 'online').length
  const offlineCount = sensorStatuses.filter((sensor) => sensor.status === 'disconnected').length

  const isSingleUnit = units.length === 1

  return (
    <div className="VOCSensor">
      {onlineCount > 0 && (
        <Label color="green" basic size="small">
          {isSingleUnit ? 'VOC sensor online' : `${onlineCount} VOC sensor${onlineCount > 1 ? 's' : ''} online`}
          &nbsp;&nbsp;&nbsp;
          <Icon name="check" fitted />
        </Label>
      )}
      {offlineCount > 0 && (
        <Popup
          content={`Please check the ${offlineCount} disconnected VOC sensor${offlineCount > 1 ? 's' : ''} or contact us for help.`}
          trigger={
            <Label basic size="small">
              <Icon name="info" fitted />
              &nbsp;&nbsp;&nbsp;
              {isSingleUnit ? 'VOC sensor disconnected' : `${offlineCount} VOC sensor${offlineCount > 1 ? 's' : ''} disconnected`}
            </Label>
          }
        />
      )}
    </div>
  )
}

export { VOCSensor }
