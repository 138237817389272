import React, { useEffect, useState, useRef } from 'react'
import { Container, Modal, Button, Header, Checkbox, Form, Icon, Divider, Segment, Message } from 'semantic-ui-react'
import { useNavigate, Link } from 'react-router-dom'
import { ajax } from '../../ajax'
import { readLocalStorageUnitData, isValidEmailAddress } from '../../common'
import { toast } from 'react-toastify'

import { phone } from 'phone'
import { postcodeValidator } from 'postcode-validator'

import './CustomerAccountSettings.css'

///////////
// TOAST //
///////////

let toastTimeoutHandle = null
const toastLoading = () => {
  clearTimeout(toastTimeoutHandle)
  toastTimeoutHandle = setTimeout(() => {
    toast('Loading ...', { containerId: 'toastPreferences', toastId: 'myToast', type: 'info', autoClose: false })
  }, 1000)
}
const toastLoaded = () => {
  clearTimeout(toastTimeoutHandle)
  toast.dismiss({ id: 'myToast' })
}
const toastSaving = () => {
  clearTimeout(toastTimeoutHandle)
  toast('Saving ...', { containerId: 'toastPreferences', toastId: 'myToast', type: 'info', autoClose: false })
  toast.update('myToast', { containerId: 'toastPreferences', render: 'Saving ...', type: 'info', autoClose: false })
}
const toastSaved = () => {
  clearTimeout(toastTimeoutHandle)
  toast.update('myToast', { containerId: 'toastPreferences', render: 'Preferences saved.', type: 'success', autoClose: 3000 })
}
const toastFormError = () => {
  clearTimeout(toastTimeoutHandle)
  toast('Please check the form for errors.', { containerId: 'toastPreferences', toastId: 'myToast', type: 'error', autoClose: 3000 })
}

///////////
///////////
///////////

function CustomerAccountSettings() {
  const firstInputRef = useRef(null)
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [saving, setSaving] = useState(false)

  const accountContactDetailsDefaults = {
    title: '',
    firstName: '',
    lastName: '',
    mobilePhoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    addressCityTown: '',
    postcode: '',
    mpanNumber: '',
    contactByEmail: true,
    contactBySms: true,
    contactByTelephone: true,
    additionalEmail1: '',
    additionalEmail2: '',
    additionalEmail3: ''
  }
  const [accountContactDetails, setAccountContactDetails] = useState(accountContactDetailsDefaults)

  const [formErrors, setFormErrors] = useState({})

  const { ownerEmail, accountId: customerAccountId } = readLocalStorageUnitData()[0]

  useEffect(() => {
    fetchCustomerAccountSettings()
    focusFirstInput()
    return () => {}
  }, [])

  function focusFirstInput() {
    setTimeout(() => {
      try {
        firstInputRef.current.focus()
      } catch (e) {}
    }, 0)
  }

  async function fetchCustomerAccountSettings() {
    setLoading(true)
    toastLoading()
    try {
      const preferences = await ajax.getPreferences({ customerAccountId })
      const isEmpty = !preferences?.accountContactDetails || Object.keys(preferences?.accountContactDetails).length === 0
      setAccountContactDetails(isEmpty ? accountContactDetailsDefaults : preferences.accountContactDetails)
      setLoading(false)
      toastLoaded()
    } catch (error) {
      console.error(error)
    }
  }

  async function postCustomerAccountSettings() {
    try {
      if (validateForm() === false) return
      setSaving(true)
      toastSaving()
      await ajax.postPreferences({ customerAccountId }, { accountContactDetails: accountContactDetails })
      setSaving(false)
      setUnsavedChanges(false)
      toastSaved()
    } catch (error) {
      console.error(error)
    }
  }

  function validateForm() {
    const _formErrors = {}

    // EMPTY FIELDS
    if (!accountContactDetails?.title) _formErrors.title = true //{ content: 'Cannot be blank' }
    if (!accountContactDetails?.firstName) _formErrors.firstName = true //{ content: 'Cannot be blank' }
    if (!accountContactDetails?.lastName) _formErrors.lastName = true //{ content: 'Cannot be blank' }
    if (!accountContactDetails?.mobilePhoneNumber) _formErrors.mobilePhoneNumber = true //{ content: 'Cannot be blank' }
    if (!accountContactDetails?.addressLine1) _formErrors.addressLine1 = true //{ content: 'Cannot be blank' }
    // if (!accountContactDetails?.addressLine2) _formErrors.addressLine2 = true //{ content: 'Cannot be blank' }
    if (!accountContactDetails?.addressCityTown) _formErrors.addressCityTown = true //{ content: 'Cannot be blank' }
    if (!accountContactDetails?.postcode) _formErrors.postcode = true //{ content: 'Cannot be blank' }
    // if (!accountContactDetails?.mpanNumber) _formErrors.mpanNumber = true //{ content: 'Cannot be blank' }
    // if (!accountContactDetails?.additionalEmail1) _formErrors.additionalEmail1 = true //{ content: 'Cannot be blank' }
    // if (!accountContactDetails?.additionalEmail2) _formErrors.additionalEmail2 = true //{ content: 'Cannot be blank' }
    // if (!accountContactDetails?.additionalEmail3) _formErrors.additionalEmail3 = true //{ content: 'Cannot be blank' }

    // MOBILE PHONE NUMBER
    if (!phone(accountContactDetails?.mobilePhoneNumber, { country: 'GB' }).isValid) _formErrors.mobilePhoneNumber = true //{ content: 'Invalid UK Mobile Phone Number' }

    // POSTCODE
    if (!postcodeValidator(accountContactDetails?.postcode, 'GB')) _formErrors.postcode = true //{ content: 'Invalid Postcoce' }

    // MPAN
    if (accountContactDetails?.mpanNumber && accountContactDetails?.mpanNumber.length < 13) _formErrors.mpanNumber = true //{ content: 'Invalid MPAN number' }

    // EMAIL ADDRESSES
    if (accountContactDetails?.additionalEmail1 && !isValidEmailAddress(accountContactDetails.additionalEmail1)) _formErrors.additionalEmail1 = true //{ content: 'Invalid email address' }
    if (accountContactDetails?.additionalEmail2 && !isValidEmailAddress(accountContactDetails.additionalEmail2)) _formErrors.additionalEmail2 = true //{ content: 'Invalid email address' }
    if (accountContactDetails?.additionalEmail3 && !isValidEmailAddress(accountContactDetails.additionalEmail3)) _formErrors.additionalEmail3 = true //{ content: 'Invalid email address' }

    const errorsExist = Object.keys(_formErrors).length !== 0

    if (errorsExist) toastFormError()

    setFormErrors(_formErrors)
    return !errorsExist
  }

  const CustomCheckbox = ({ id, label, disabled = false }) => (
    <Checkbox
      toggle
      disabled={disabled}
      label={label}
      checked={accountContactDetails?.[id]}
      id={id}
      onChange={async (e, { id, checked }) => {
        setUnsavedChanges(true)
        setAccountContactDetails((prev) => ({ ...prev, [id]: checked }))
      }}
    />
  )

  const handleInputChange = (e, { id, value }) => {
    setUnsavedChanges(true)
    setAccountContactDetails((prev) => ({ ...prev, [id]: value }))
  }

  return (
    <>
      <Modal.Header>
        <Icon name="cogs" />
        &nbsp;&nbsp;&nbsp;My Profile
      </Modal.Header>
      <Modal.Content className="CustomerAccountSettings" scrolling>
        {customerAccountId === null && <Message error header="No Customer Account attached!" content="You will not be able to load or save account details." />}
        <Segment basic vertical loading={loading || saving}>
          <Header>Account Contact Information</Header>
          <Divider hidden />
          <Container text>
            <Form>
              <Form.Input label="Email Address">
                <input value={ownerEmail} disabled />
              </Form.Input>
              <Form.Group widths="equal">
                <Form.Select
                  width={3}
                  fluid
                  id="title"
                  label="Title"
                  onChange={handleInputChange}
                  value={accountContactDetails?.title}
                  error={formErrors?.title}
                  options={[
                    { key: 'mr', text: 'Mr', value: 'Mr' },
                    { key: 'mrs', text: 'Mrs', value: 'Mrs' },
                    { key: 'miss', text: 'Miss', value: 'Miss' },
                    { key: 'ms', text: 'Ms', value: 'Ms' },
                    { key: 'dr', text: 'Dr', value: 'Dr' }
                  ]}
                />

                <Form.Input
                  id="firstName"
                  label="First Name"
                  onChange={handleInputChange}
                  value={accountContactDetails?.firstName}
                  error={formErrors?.firstName}
                >
                  <input ref={firstInputRef} />
                </Form.Input>
                <Form.Input id="lastName" label="Last Name" onChange={handleInputChange} value={accountContactDetails?.lastName} error={formErrors?.lastName} />
              </Form.Group>
              <Form.Input
                id="mobilePhoneNumber"
                label={
                  <strong>
                    Mobile Phone Number&nbsp;&nbsp;
                    <span style={{ opacity: 0.4 }}>
                      (UK only. Please{' '}
                      <span
                        className="span-link"
                        onClick={() => {
                          if (unsavedChanges) {
                            if (window.confirm('There are unsaved changes, are you sure you want to leave this page?')) navigate('/home/help')
                          } else navigate('/home/help')
                        }}
                      >
                        contact us
                      </span>{' '}
                      if you need to change it.)
                    </span>
                  </strong>
                }
                onChange={handleInputChange}
                value={accountContactDetails?.mobilePhoneNumber}
                error={formErrors?.mobilePhoneNumber}
              />
              <Form.Input
                id="addressLine1"
                label="Address Line 1"
                onChange={handleInputChange}
                value={accountContactDetails?.addressLine1}
                error={formErrors?.addressLine1}
              />
              <Form.Input
                id="addressLine2"
                label="Address Line 2"
                onChange={handleInputChange}
                value={accountContactDetails?.addressLine2}
                error={formErrors?.addressLine2}
              />
              <Form.Input
                id="addressCityTown"
                label="City/Town"
                onChange={handleInputChange}
                value={accountContactDetails?.addressCityTown}
                error={formErrors?.addressCityTown}
              />
              <Form.Input id="postcode" label="Postcode" onChange={handleInputChange} value={accountContactDetails?.postcode} error={formErrors?.postcode} />
              <Form.Input
                id="mpanNumber"
                label={
                  <>
                    <strong>MPAN Number</strong>
                    <br />
                    <small style={{ opacity: 0.7 }}>(13-digit number relating to your smart meter, found on your electricity bill)</small>
                  </>
                }
                onChange={handleInputChange}
                value={accountContactDetails?.mpanNumber}
                error={formErrors?.mpanNumber}
              />
            </Form>
          </Container>
        </Segment>

        <Divider hidden />
        <Divider />

        <Segment basic vertical loading={loading || saving}>
          <Header>Contact Preferences</Header>
          <p>Please tell us all the ways you'd like to hear from us:</p>
          <Divider hidden />
          <Container text>
            <Form>
              <CustomCheckbox id="contactByEmail" label="Email" />
              <Divider hidden />
              <CustomCheckbox id="contactBySms" label="SMS" />
              <Divider hidden />
              <CustomCheckbox id="contactByTelephone" label="Telephone" />
            </Form>
          </Container>
        </Segment>

        <Divider hidden />
        <Divider />

        <Segment basic vertical loading={loading || saving}>
          <Header>Additional Users</Header>
          <p>
            Optionally, if you would like to grant other people access to your Portal, please enter their email addresses. <br />
            <span style={{ opacity: 0.5 }}>
              <strong>(They will each need to sign up to the Portal afterwards)</strong>
            </span>
          </p>
          <Divider hidden />
          <Container text>
            <Form>
              <Form.Input
                id="additionalEmail1"
                label={
                  <strong>
                    Additional Email 1&nbsp;&nbsp;<span style={{ opacity: 0.4 }}>(Optional)</span>
                  </strong>
                }
                onChange={handleInputChange}
                value={accountContactDetails?.additionalEmail1}
                error={formErrors?.additionalEmail1}
              />{' '}
              <Form.Input
                id="additionalEmail2"
                label={
                  <strong>
                    Additional Email 2&nbsp;&nbsp;<span style={{ opacity: 0.4 }}>(Optional)</span>
                  </strong>
                }
                onChange={handleInputChange}
                value={accountContactDetails?.additionalEmail2}
                error={formErrors?.additionalEmail2}
              />{' '}
              <Form.Input
                id="additionalEmail3"
                label={
                  <strong>
                    Additional Email 3&nbsp;&nbsp;<span style={{ opacity: 0.4 }}>(Optional)</span>
                  </strong>
                }
                onChange={handleInputChange}
                value={accountContactDetails?.additionalEmail3}
                error={formErrors?.additionalEmail3}
              />
            </Form>
          </Container>
        </Segment>

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
      </Modal.Content>
      <Modal.Actions>
        {unsavedChanges ? (
          <>
            <Button disabled={loading || saving} primary onClick={postCustomerAccountSettings}>
              Save changes
            </Button>
            <Button
              disabled={loading || saving}
              secondary
              onClick={() => {
                navigate(-1)
              }}
            >
              Discard changes
            </Button>
          </>
        ) : (
          <>
            <Button disabled={loading || saving} secondary onClick={() => navigate(-1)}>
              Done
            </Button>
          </>
        )}
      </Modal.Actions>
    </>
  )
}

export { CustomerAccountSettings }
