import React, { useState, useEffect } from 'react'
import { Modal, Button, Menu, Segment, Image, Grid, Message, Loader, Header, Icon, Divider, Dimmer, Popup } from 'semantic-ui-react'
import { Link, useNavigate } from 'react-router-dom'

function P5HardRebootProcedure() {
  return (
    <>
      <Header>P5 Hard Reboot Procedure</Header>
      <p>If you ever need to hard reboot your Powervault P5, please follow the explainer video instructions in the links below:</p>
      <Button
        icon="external"
        labelPosition="left"
        as={Link}
        primary
        basic
        content="Rebooting (with solar)"
        to="https://youtube.com/shorts/XBV2e1h7wb4"
        target="_blank"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Button
        icon="external"
        labelPosition="left"
        as={Link}
        primary
        basic
        content="Rebooting (without solar)"
        to="https://youtube.com/shorts/P6CpPzdbx7Y"
        target="_blank"
      />
      <Divider hidden />
      <p>If you need further help please do not hesitate to contact customer service.</p>
      <Button primary basic as="a" href="mailto:service@powervault.co.uk?subject=Powervault%20Offline" target="_blank" referrerPolicy="no-referrer">
        Contact Customer Service
      </Button>
    </>
  )
}

export { P5HardRebootProcedure }
