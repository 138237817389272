import React, { useState, useEffect } from 'react'
import { Segment, Message, Header, Button, Icon } from 'semantic-ui-react'
import moment from 'moment'
import { ajax } from '../../ajax'
import { authenticator } from '../../authenticator'
import { debugMessage } from '../../common'
import { getEnglishText } from '../../dictionary'

import './ForceChargeDischarge.css'

let refreshHandle = null
const refreshInterval = 10000
const refreshIntervalAfterCommand = 30000
const savedCooldownDuration = 30000

function ForceChargeDischarge({ hardwareIds }) {
  const [loading, setLoading] = useState(true)
  const [changes, setChanges] = useState(false)
  const [saving, setSaving] = useState(false)
  const [savedCooldown, setSavedCooldown] = useState(null)

  const [stateOverrides, setStateOverrides] = useState([])

  const [editMode, setEditMode] = useState(null)
  const [relativeDateDisplay, setRelativeDateDisplay] = useState(true)
  const [duration, setDuration] = useState(null)
  const [message, setMessage] = useState(null)

  const maxOverrideDuration = 180

  useEffect(() => {
    debugMessage('Mounted', 'ForceChargeDischarge')
    fetchStateOverrides()
    return () => {
      clearTimeout(refreshHandle)
    }
  }, [])

  function fetchStateOverrides() {
    clearTimeout(refreshHandle)
    ajax
      .fetchStateOverrides(authenticator.getToken(), hardwareIds[0].unitId)
      .then((data) => {
        setStateOverrides(data)
        setLoading(false)
        refreshHandle = setTimeout(() => fetchStateOverrides(), refreshInterval) // set next refresh
      })
      .catch((err) => {
        console.error(err)
      })
  }

  function putStateOverride({ stateOverride, cooldownType = null }) {
    setSaving(true)
    clearTimeout(refreshHandle)
    let errors = []

    for (let hardwareId of hardwareIds) {
      ajax.putStateOverride(authenticator.getToken(), hardwareId.unitId, stateOverride).catch((err) => {
        errors.push(err)
        console.error(err)
      })
    }

    if (!errors.length) {
      // all ok
      if (cooldownType) {
        setSavedCooldown(cooldownType)
        setTimeout(() => setSavedCooldown(null), savedCooldownDuration)
      }
      setSaving(false)
      setChanges(false)
      setEditMode(false)
      setDuration(null)
      setStateOverrides([stateOverride])
      setRelativeDateDisplay(true)
      refreshHandle = setTimeout(() => fetchStateOverrides(), refreshIntervalAfterCommand) // set next refresh to 30 seconds to give unit time to respond
    } else {
      // at least one error
      setSaving(false)
      refreshHandle = setTimeout(() => fetchStateOverrides(), refreshInterval) // set next refresh
    }
  }

  function save({ cooldownType = null, add = false }) {
    putStateOverride({
      stateOverride: {
        start: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
        end: add
          ? moment(add).utc(true).add(duration, 'minutes').format('YYYY-MM-DD HH:mm:ss')
          : moment().utc().add(duration, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
        state: editMode
      },
      cooldownType
    })
  }

  function endNow() {
    const { state } = getPrimaryStateOverride()
    putStateOverride({
      stateOverride: {
        start: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
        end: moment().utc().add(savedCooldownDuration, 'milliseconds').format('YYYY-MM-DD HH:mm:ss'),
        state
      },
      cooldownType: true
    })
  }

  function getPrimaryStateOverride() {
    const portal = stateOverrides.filter((so) => so.source === 'p3-portal')
    const other = stateOverrides.filter((so) => so.source !== 'p3-portal')
    return other.length ? other[0] : portal[0]
  }

  function portalStateOverrideExists() {
    return stateOverrides.filter((so) => so.source === 'p3-portal').length > 0
  }

  function nonPortalStateOverrideExists() {
    return stateOverrides.filter((so) => so.source !== 'p3-portal').length > 0
  }

  function friendlyStateOverrideState(state) {
    switch (state) {
      case 'force-charge':
        return getEnglishText('temporary-overrides : force-charge')
      case 'force-discharge':
        return getEnglishText('temporary-overrides : force-discharge')
      case 'normal':
        return getEnglishText('temporary-overrides : normal-override')
      case 'disable':
        return getEnglishText('temporary-overrides : disable-override')
      default:
        return ''
    }
  }

  function friendlyDuration() {
    if (relativeDateDisplay) {
      switch (duration) {
        case 15:
          return '15 minutes'
        case 30:
          return '30 minutes'
        case 60:
          return '1 hour'
        default:
          return `${duration / 60} hours`
      }
    } else {
      return moment().add(duration, 'minutes').format('HH:mm')
    }
  }

  function friendlySummary(state) {
    switch (state) {
      case 'force-charge':
        return getEnglishText('temporary-overrides : force-charge-description')
      case 'force-discharge':
        return getEnglishText('temporary-overrides : force-discharge-description')
      case 'normal':
        return getEnglishText('temporary-overrides : normal-override-description')
      case 'only-charge':
        return getEnglishText('temporary-overrides : disable-override-description')
      default:
        return ''
    }
  }

  function increaseDuration() {
    setChanges(true)
    switch (duration) {
      case null:
        setDuration(15)
        break
      case 15:
        setDuration(duration + 15)
        break
      default:
        setDuration(duration + 30)
        break
    }
  }

  function StateOverrideDetail() {
    const editable = true // TODO: use line below once iot_manager accepts `source` field
    // const editable = !nonPortalStateOverrideExists()

    const { start, end, state } = getPrimaryStateOverride()

    return (
      <div className="override-message">
        {moment(start).isAfter(moment.utc()) ? (
          <>
            <div className="title">
              <Header as="h3">
                Override scheduled&nbsp;&nbsp;
                <Icon name="bolt" className="pv-yellow-color" />
              </Header>
            </div>
            <div></div>
            <p>Cancel via Severe Weather Alert SMS</p>
          </>
        ) : (
          <>
            <div className="title">
              <Header as="h3">
                {friendlyStateOverrideState(state)} {getEnglishText('temporary-overrides : override')}&nbsp;&nbsp;
                <Icon name="bolt" className="pv-yellow-color" />
              </Header>
            </div>
            <div></div>

            {savedCooldown && savedCooldown !== true ? (
              <>
                <span>{savedCooldown} ...</span>
              </>
            ) : (
              <>
                {editMode ? (
                  ///////////////////
                  // Edit-existing //
                  ///////////////////
                  <>
                    <span onClick={() => setRelativeDateDisplay(!relativeDateDisplay)}>
                      {getEnglishText('temporary-overrides : ends')}{' '}
                      <span className="pv-primary-color">
                        {relativeDateDisplay
                          ? `${moment(end).add(duration, 'minutes').fromNow()}.`
                          : `at ${moment(end).add(duration, 'minutes').format('HH:mm')}`}
                      </span>
                    </span>

                    <div className="no-select">
                      <Button
                        basic
                        primary
                        content={getEnglishText('temporary-overrides : extend-time')}
                        onClick={increaseDuration}
                        disabled={duration >= maxOverrideDuration}
                        icon="plus"
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {changes && (
                        <Button
                          primary
                          content={getEnglishText('common : save')}
                          onClick={() => save({ cooldownType: getEnglishText('temporary-overrides : extending'), add: end })}
                        />
                      )}
                      <br />
                      <Button
                        secondary
                        size="small"
                        content={getEnglishText('common : cancel')}
                        onClick={() => {
                          setEditMode(null)
                          setDuration(null)
                          setRelativeDateDisplay(true)
                        }}
                      />
                    </div>
                  </>
                ) : (
                  /////////////////////////////
                  // Existing state override //
                  /////////////////////////////
                  <>
                    <span onClick={() => setRelativeDateDisplay(!relativeDateDisplay)}>
                      {getEnglishText('temporary-overrides : ends')}{' '}
                      <span className="pv-primary-color">
                        {relativeDateDisplay ? `${moment.utc(end).fromNow()}.` : `at ${moment.utc(end).local().format('HH:mm')}`}
                      </span>
                    </span>

                    {editable && !savedCooldown && (
                      <div>
                        <Button
                          basic
                          primary
                          content={getEnglishText('temporary-overrides : extend-time')}
                          onClick={() => {
                            setDuration(15)
                            setChanges(true)
                            setEditMode(state)
                            setRelativeDateDisplay(false)
                          }}
                          disabled={duration >= maxOverrideDuration}
                          icon="plus"
                        />
                        <Button secondary size="small" content={getEnglishText('temporary-overrides : end-now')} onClick={endNow} />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    )
  }

  return (
    <div id="ForceChargeDischarge" className="hide-more">
      <Segment textAlign="center" basic loading={loading || saving}>
        {message && <Message {...message} />}

        {nonPortalStateOverrideExists() ? (
          ////////////////////////////////////////////////
          // A stateOverride exists from another source //
          ////////////////////////////////////////////////
          // Note that ALL state overrides will be considered as "sent from another source" because the units do not echo back the `source` part of the AWS IOT message
          <>
            <StateOverrideDetail />
          </>
        ) : (
          <>
            {portalStateOverrideExists() ? (
              ///////////////////////////////////
              // A Portal stateOverride exists //
              ///////////////////////////////////
              <>
                <StateOverrideDetail />
              </>
            ) : (
              <>
                {editMode ? (
                  ///////////////
                  // Edit mode //
                  ///////////////
                  <div className="edit-container">
                    <div className="edit-message" onClick={() => setRelativeDateDisplay(!relativeDateDisplay)}>
                      <strong>
                        {friendlyStateOverrideState(editMode)}{' '}
                        {relativeDateDisplay ? getEnglishText('temporary-overrides : for') : getEnglishText('temporary-overrides : until')}{' '}
                        <span>{friendlyDuration()}</span>
                      </strong>
                      <p style={{ marginTop: '1em', opacity: 0.7 }}>{friendlySummary(editMode)}</p>
                    </div>
                    <div className="no-select">
                      <Button
                        basic
                        primary
                        content={getEnglishText('temporary-overrides : extend-time')}
                        onClick={increaseDuration}
                        disabled={duration >= maxOverrideDuration}
                        icon="plus"
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {changes && (
                        <Button
                          primary
                          content={getEnglishText('common : save')}
                          onClick={() => save({ cooldownType: getEnglishText('temporary-overrides : starting') })}
                        />
                      )}
                      <br />
                      <Button
                        secondary
                        size="small"
                        content={getEnglishText('common : cancel')}
                        onClick={() => {
                          setEditMode(null)
                          setDuration(null)
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  //////////////////
                  // Initial mode //
                  //////////////////
                  <div className="default-container no-select">
                    <div className="title">
                      <Header as="h4">
                        {getEnglishText('temporary-overrides : title')}&nbsp;&nbsp;
                        <Icon name="bolt" className="pv-primary-color" />
                      </Header>
                      {/* <Label className="beta-badge" content="beta" color="red" size="mini" /> */}
                    </div>
                    <div></div>
                    <Button
                      // basic
                      primary
                      content={getEnglishText('temporary-overrides : force-charge')}
                      onClick={() => {
                        setEditMode('force-charge')
                        increaseDuration()
                        setRelativeDateDisplay(true)
                      }}
                    />
                    <Button
                      // basic
                      primary
                      content={getEnglishText('temporary-overrides : force-discharge')}
                      onClick={() => {
                        setEditMode('force-discharge')
                        increaseDuration()
                        setRelativeDateDisplay(true)
                      }}
                    />
                    <br />
                    <Button
                      size="mini"
                      basic
                      primary
                      content={getEnglishText('temporary-overrides : normal-override')}
                      onClick={() => {
                        setEditMode('normal')
                        increaseDuration()
                        setRelativeDateDisplay(true)
                      }}
                    />
                    <Button
                      size="mini"
                      basic
                      primary
                      content={getEnglishText('temporary-overrides : disable-override')}
                      onClick={() => {
                        setEditMode('only-charge')
                        increaseDuration()
                        setRelativeDateDisplay(true)
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </Segment>
    </div>
  )
}

export { ForceChargeDischarge }
